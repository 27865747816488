import { da } from "date-fns/locale";
import { FC } from "react";
// @ts-ignore
import { registerLocale } from "react-datepicker";
import {
  AccountSettings
} from "../../../Generated/graphql";

import { toast, Zoom } from "react-toastify";
import { Button } from "../../Button/Button";
import { ListItem } from "../../ListItem/ListItem";
import "./SettingsAdminContent.css";

registerLocale("da", da);

type Props = {
  settings: AccountSettings;
};

export const SettingsAdminContent: FC<Props> = ({ settings }) => {
  
  

  return (
    <>
      <div className="SettingsAdminContent">
        <div className="SettingsAdmin__item">
          <ListItem title="Synkroniser lagerværdier med BC">
            <div className="SettingsAdmin__checkbox">
            <Button
            buttonStyle="newItem"
            labelStyle="createButton"
            click={() => {
              Promise.allSettled([                
              ]).then(() => {
                toast("Synkronisering af lagerværider startet", {
                  className: "u-toast-success",
                  progressClassName: "u-toast-success-bar",
                  transition: Zoom,
                });
              });
            }}
          >
            Synkroniser
          </Button>
            </div>
          </ListItem>
        </div>
      </div>
      <div className="SettingsAdmin__actions">
        <div className="SettingsNewUser__confirm-button">
          <Button
            buttonStyle="newItem"
            labelStyle="createButton"
            inactive={true}            
          >
            Gem ændringer
          </Button>
        </div>
      </div>
    </>
  );
};