import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { BoxLoader } from "../../Components/BoxLoader/BoxLoader";
import { SettingsAccess } from "../../Components/SettingsAccess/SettingsAccess";
import { SettingsAdmin } from "../../Components/SettingsAdmin/SettingsAdmin";
import { SettingsCompany } from "../../Components/SettingsCompany/SettingsCompany";
import { SettingsHistory } from "../../Components/SettingsHistory/SettingsHistory";
import { SettingsMenuItem } from "../../Components/SettingsMenuItem/SettingsMenuItem";
import { SettingsScanner } from "../../Components/UserSettings/SettingsScanner/SettingsScanner";
import { useUserQuery } from "../../Generated/graphql";
import "./UserSettings.css";

export const UserSettings = () => {
  const { data, error, loading } = useUserQuery();
  const history = useHistory();
  const location = useLocation();

  const goToLocation = (location: string) => {
    history.push(`/settings/${location}`);
  };
  const activeRoute = location.pathname;

  if (loading) return <BoxLoader />;
  if (error) return <div>{error.message}</div>;

  if (data?.user.role === "User") {
    return <Redirect to="/" />;
  }

  return (
    <div className="UserSettings">
      <div className="UserSettings__title">
        <div className="UserSettings__title-text">Indstillinger</div>
      </div>
      <div className="UserSettings__menu-container">
        <div className="UserSettings__menu">
          <div className="UserSettings__menu-item UserSettings__menu-item-first">
            <SettingsMenuItem
              label="Kontoindstillinger"
              click={() => goToLocation("access")}
              active={activeRoute === "/settings/access" ? true : false}
            />
          </div>
          <div className="UserSettings__menu-item">
            <SettingsMenuItem
              label="Ordrehåndtering"
              click={() => goToLocation("company")}
              active={activeRoute === "/settings/company" ? true : false}
            />
          </div>
          <div className="UserSettings__menu-item">
            <SettingsMenuItem
              label="Håndscanner"
              click={() => goToLocation("scanner")}
              active={activeRoute === "/settings/scanner" ? true : false}
            />
          </div>
          <div className="UserSettings__menu-item">
            <SettingsMenuItem
              label="Historik"
              click={() => goToLocation("history")}
              active={activeRoute === "/settings/history" ? true : false}
            />
          </div>
          <div className="UserSettings__menu-item">
            <SettingsMenuItem
              label="Administratorindstillinger"
              click={() => goToLocation("admin")}
              active={activeRoute === "/settings/admin" ? true : false}
            />
          </div>
        </div>
      </div>
      <div className="UserSettings__content">
        <Route
          render={() => (
            <>
              <Switch>
                <Route path="/settings/access" component={SettingsAccess} />
                <Route path="/settings/company" component={SettingsCompany} />
                <Route path="/settings/scanner" component={SettingsScanner} />
                <Route path="/settings/history" component={SettingsHistory} />
                <Route
                  exact
                  path="/settings"
                  render={() => <Redirect to="/settings/access" />}
                />
                <Route path="/settings/admin" component={SettingsAdmin} />
              </Switch>
            </>
          )}
        />
      </div>
    </div>
  );
};
