import { first, throttle } from "lodash";
import { useEffect, useState } from "react";
import {
  NavLink,
  // useHistory, 
  useLocation
} from "react-router-dom";
import { Icon } from "../../../Components/Icon/Icon";
import { NavigationItem } from "../../../Components/NavigationItem/NavigationItem";
import { OrdersDateChangedBanner } from "../../../Components/OrdersDateChangedBanner/OrdersDateChangedBanner";
import {
  useCartsQuery,
  useOrdersQuery,
  useUserQuery,
} from "../../../Generated/graphql";

import "./Navigation.css";

export const Navigation = () => {
  const { data } = useUserQuery();
  // const history = useHistory();
  const location = useLocation();
  const [orderNeedingAttention, setOrderNeedingAttention] = useState(0);

  const { data: cartsData } = useCartsQuery();

  const { data: ordersData } = useOrdersQuery();

  useEffect(() => {
    if (!ordersData) {
      return;
    }
    setOrderNeedingAttention(
      ordersData.orders.filter((x) => x.acknowledged === true && x.picked === true && x.completed !== true)
        .length
    );
  }, [ordersData]);

  let inactivityMonitor: NodeJS.Timeout;

  const resetInactivityMonitor = () => {
    
    clearTimeout(inactivityMonitor);
    // startInactivityMonitor();
    throttledResetInactivityMonitor.cancel();
  };

  const throttledResetInactivityMonitor = throttle(
    resetInactivityMonitor,
    2000
  );

  useEffect(() => {
    document.addEventListener("mousemove", throttledResetInactivityMonitor);
    document.addEventListener("keydown", throttledResetInactivityMonitor);

    return () => {
      document.removeEventListener(
        "mousemove",
        throttledResetInactivityMonitor
      );
      document.removeEventListener("keydown", throttledResetInactivityMonitor);
    };
  }, [throttledResetInactivityMonitor]);

  // const startInactivityMonitor = () => {
  //   // eslint-disable-next-line no-restricted-globals
  //   if (!window.location.hostname.includes("local")) {
  //     inactivityMonitor = setTimeout(() => {
  //       const modalRoot = document.querySelector("#modal-root");

  //       if (modalRoot) {
  //         const hasModalActive = modalRoot.hasChildNodes();
  //         if (!hasModalActive) {
  //           history.push("/");
  //         }
  //       } else {
  //         history.push("/");
  //       }
  //     }, 10000);
  //   }
  // };

  const activeRoute = location.pathname;

  const activeOrderItemAmount =
    (cartsData?.carts && first(cartsData?.carts)?.orderLines?.length) ?? 0;

  return (
    <>
      <div className="Navigation">
        <div className="Navigation__items">
          <NavLink to="/">
            <NavigationItem
              logoActive="scanActive"
              logoInactive="scanInactive"
              active={activeRoute === "/" ? true : false}
              amount={0}
            >
              Scan
            </NavigationItem>
          </NavLink>

          <NavLink to="/inventory" activeClassName="">
            <NavigationItem
              logoActive="warehouseActive"
              logoInactive="warehouseInactive"
              active={activeRoute.includes("/inventory") ? true : false}
              amount={0}
            >
              Lager
            </NavigationItem>
          </NavLink>

          <NavLink to="/orders" activeClassName="">
            <NavigationItem
              logoActive="cartActive"
              logoInactive="cartInactive"
              active={activeRoute === "/orders" ? true : false}
              amount={activeOrderItemAmount}
            >
              Bestilling
            </NavigationItem>
          </NavLink>

          <NavLink to="/incoming" activeClassName="">
            <NavigationItem
              logoActive="incommingGoodsActive"
              logoInactive="incommingGoodsInactive"
              active={activeRoute === "/incoming" ? true : false}
              amount={orderNeedingAttention}
            >
              Modtagelse
            </NavigationItem>
          </NavLink>

          {data?.user.role === "ADMIN" && (
            <NavLink to="/dashboard" activeClassName="">
              <NavigationItem
                logoActive="navDashboardEnabled"
                logoInactive="navDashboardDisabled"
                active={activeRoute === "/dashboard" ? true : false}
                amount={0}
              >
                Overblik
              </NavigationItem>
            </NavLink>
          )}
        </div>

        <div className="Navigation__logo">
          <Icon name="bdjLogo" width={25} />
        </div>
      </div>

      <>
        <OrdersDateChangedBanner
          show={cartsData?.carts && first(cartsData?.carts)?.wasDateChanged}
        />
      </>
    </>
  );
};
