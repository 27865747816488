import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
};

export type AccountLog = {
  __typename?: 'AccountLog';
  accountId: Scalars['String'];
  createdAt: Scalars['String'];
  extraData?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  message: Scalars['String'];
  operationGroup: Scalars['String'];
  operationType: Scalars['String'];
};

export type AccountSettings = {
  __typename?: 'AccountSettings';
  accountName: Scalars['String'];
  accountancyYear?: Maybe<Scalars['Int']>;
  automaticOrderDays: Array<Maybe<AutomaticOrderDay>>;
  automaticOrderInterval: Scalars['String'];
  avoidHolidays?: Maybe<Scalars['Boolean']>;
  countOnScan?: Maybe<Scalars['Boolean']>;
  cvr?: Maybe<Scalars['String']>;
  hasAutomaticOrdering?: Maybe<Scalars['Boolean']>;
  hasLechler?: Maybe<Scalars['Boolean']>;
  hasPercotop?: Maybe<Scalars['Boolean']>;
  hasSpiesHecker?: Maybe<Scalars['Boolean']>;
  hasStandox?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  ignoredProducts?: Maybe<Array<Maybe<Scalars['String']>>>;
  isActive?: Maybe<Scalars['Boolean']>;
  usersCanEdit: Scalars['Boolean'];
  usersCanExport: Scalars['Boolean'];
};

export type AutomaticOrderDay = {
  __typename?: 'AutomaticOrderDay';
  day: Scalars['String'];
  time: Scalars['String'];
};

export type AutomaticOrderDayInput = {
  day: Scalars['String'];
  time: Scalars['String'];
};

export enum AutomaticOrdering {
  Active = 'active',
  Deactivated = 'deactivated',
  Disabled = 'disabled'
}

export type Cart = {
  __typename?: 'Cart';
  automaticOrderDate: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  orderLines: Array<CartItem>;
  wasDateChanged: Scalars['Boolean'];
};

export type CartInput = {
  comment?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  orderDate: Scalars['String'];
  orderLines: Array<CartItemInput>;
  wasDateChanged: Scalars['Boolean'];
};

export type CartItem = {
  __typename?: 'CartItem';
  amount: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  images: Array<Image>;
  manuallyAdded: Scalars['Boolean'];
  name: Scalars['String'];
  productNumber: Scalars['String'];
};

export type CartItemInput = {
  amount: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  images: Array<ImageInput>;
  manuallyAdded: Scalars['Boolean'];
  name: Scalars['String'];
  productNumber: Scalars['String'];
};

export type CreateAccountInput = {
  cvr?: InputMaybe<Scalars['String']>;
  hasLechler: Scalars['Boolean'];
  hasPercotop: Scalars['Boolean'];
  hasSpiesHecker: Scalars['Boolean'];
  hasStandox: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  startDate: Scalars['String'];
};

export type Holiday = {
  __typename?: 'Holiday';
  day?: Maybe<Scalars['String']>;
  holidayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type Image = {
  __typename?: 'Image';
  name: Scalars['String'];
  sorting?: Maybe<Scalars['Int']>;
  url: Scalars['String'];
};

export type ImageInput = {
  name: Scalars['String'];
  sorting?: InputMaybe<Scalars['Int']>;
  url: Scalars['String'];
};

export type InventoryItem = {
  __typename?: 'InventoryItem';
  accountId: Scalars['String'];
  alreadyOrdered: Scalars['Boolean'];
  amount: Scalars['Int'];
  amountPerUnit: Scalars['Int'];
  automaticOrderingState: AutomaticOrdering;
  barcode: Scalars['String'];
  category: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  images: Array<Maybe<Image>>;
  inCart: Scalars['Boolean'];
  isCustom: Scalars['Boolean'];
  maximum: Scalars['Int'];
  minimum: Scalars['Int'];
  name: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  productNumber: Scalars['String'];
  status: Scalars['Int'];
  supplier?: Maybe<Scalars['String']>;
  unit: Scalars['String'];
};

export type InventoryItemHistoryEntry = {
  __typename?: 'InventoryItemHistoryEntry';
  accountId: Scalars['String'];
  eventAction: InventoryItemHistoryEntryEventAction;
  eventReverted: Scalars['Boolean'];
  eventTime: Scalars['String'];
  eventType: InventoryItemHistoryEntryEventType;
  fromOrderNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  productIds: Array<Scalars['String']>;
  productNumber: Scalars['String'];
  revertTime?: Maybe<Scalars['String']>;
};

export enum InventoryItemHistoryEntryEventAction {
  Manual = 'manual',
  Ordered = 'ordered',
  Receive = 'receive',
  Scan = 'scan'
}

export enum InventoryItemHistoryEntryEventType {
  Add = 'add',
  Remove = 'remove'
}

export type InventoryValue = {
  __typename?: 'InventoryValue';
  changeDirection?: Maybe<Scalars['String']>;
  changePercent?: Maybe<Scalars['Float']>;
  currentValue?: Maybe<Scalars['Float']>;
};

export type InventoryValueChartData = {
  __typename?: 'InventoryValueChartData';
  intakeValue?: Maybe<Scalars['Float']>;
  inventoryValue?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  outgoingValue?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Int']>;
};

export type MaterialValue = {
  __typename?: 'MaterialValue';
  category?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  percentChange?: Maybe<Scalars['Float']>;
  prodNo?: Maybe<Scalars['String']>;
  productData?: Maybe<Scalars['JSON']>;
  value?: Maybe<Scalars['Float']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addCustomItem: InventoryItem;
  addHoliday: Holiday;
  addInventoryItem: InventoryItem;
  addItemsToCart: Array<Maybe<Cart>>;
  addScannedItem?: Maybe<Scan>;
  createAccount: AccountSettings;
  deleteInventoryItem: Scalars['String'];
  deleteScannedItem?: Maybe<Scan>;
  moveToActiveCart: Array<Maybe<Cart>>;
  orderCart: Scalars['Boolean'];
  regretInventoryItemHistoryEntry: InventoryItemHistoryEntry;
  removeHoliday: Scalars['String'];
  removeItemFromCart: Array<Cart>;
  setCartComment: Array<Cart>;
  updateAccount: AccountSettings;
  updateAccountSettings: AccountSettings;
  updateCartItem: Array<Cart>;
  updateCustomItem: InventoryItem;
  updateHoliday: Holiday;
  updateInventoryItem: InventoryItem;
  updateOrderItems: Order;
  updateScannedItem?: Maybe<Scan>;
  updateWatchedProductStatus: WatchedProduct;
};


export type MutationAddCustomItemArgs = {
  barcode?: InputMaybe<Scalars['String']>;
  category: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  inStock: Scalars['Int'];
  name: Scalars['String'];
  price?: InputMaybe<Scalars['Int']>;
  supplier?: InputMaybe<Scalars['String']>;
  units?: InputMaybe<Scalars['String']>;
};


export type MutationAddHolidayArgs = {
  day: Scalars['String'];
  holiday_name: Scalars['String'];
};


export type MutationAddInventoryItemArgs = {
  action: Scalars['String'];
  automaticOrdering?: InputMaybe<AutomaticOrdering>;
  fromOrder?: InputMaybe<Scalars['String']>;
  inStock: Scalars['Int'];
  isCustom: Scalars['Boolean'];
  maximum: Scalars['Int'];
  minimum: Scalars['Int'];
  price?: InputMaybe<Scalars['Float']>;
  productNumber: Scalars['String'];
};


export type MutationAddItemsToCartArgs = {
  items: Array<CartItemInput>;
};


export type MutationAddScannedItemArgs = {
  barcode: Scalars['String'];
};


export type MutationCreateAccountArgs = {
  args: CreateAccountInput;
};


export type MutationDeleteInventoryItemArgs = {
  productNumber: Scalars['String'];
};


export type MutationDeleteScannedItemArgs = {
  prodNo: Scalars['String'];
  scanId: Scalars['String'];
};


export type MutationMoveToActiveCartArgs = {
  cartId: Scalars['String'];
  productNumber: Scalars['String'];
};


export type MutationOrderCartArgs = {
  cartId: Scalars['String'];
};


export type MutationRegretInventoryItemHistoryEntryArgs = {
  id: Scalars['String'];
};


export type MutationRemoveHolidayArgs = {
  id: Scalars['String'];
};


export type MutationRemoveItemFromCartArgs = {
  item: CartItemInput;
  moveToNextCart: Scalars['Boolean'];
};


export type MutationSetCartCommentArgs = {
  cartId: Scalars['String'];
  comment?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateAccountArgs = {
  args: UpdateAccountInput;
};


export type MutationUpdateAccountSettingsArgs = {
  args: UpdateAccountSettingsInput;
};


export type MutationUpdateCartItemArgs = {
  cartId: Scalars['String'];
  item: CartItemInput;
};


export type MutationUpdateCustomItemArgs = {
  barcode?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  inStock?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  supplier?: InputMaybe<Scalars['String']>;
  units?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateHolidayArgs = {
  day?: InputMaybe<Scalars['String']>;
  holiday_name?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationUpdateInventoryItemArgs = {
  action?: InputMaybe<Scalars['String']>;
  automaticOrdering?: InputMaybe<AutomaticOrdering>;
  fromOrder?: InputMaybe<Scalars['String']>;
  inStock?: InputMaybe<Scalars['Int']>;
  maximum?: InputMaybe<Scalars['Int']>;
  minimum?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Float']>;
  productNumber: Scalars['String'];
};


export type MutationUpdateOrderItemsArgs = {
  items: Array<OrderItemInput>;
  newItems: Array<OrderNewItemInput>;
  orderId: Scalars['String'];
  withWarningMessage?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateScannedItemArgs = {
  id: Scalars['String'];
  newAmount: Scalars['Int'];
  prodNo: Scalars['String'];
};


export type MutationUpdateWatchedProductStatusArgs = {
  id?: InputMaybe<Scalars['String']>;
  response?: InputMaybe<Scalars['String']>;
};

export type Order = {
  __typename?: 'Order';
  accountId: Scalars['String'];
  acknowledged: Scalars['Boolean'];
  backorder: Scalars['Boolean'];
  cancelled: Scalars['Boolean'];
  comments: Array<Maybe<Scalars['String']>>;
  completed: Scalars['Boolean'];
  deliveryId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invoiced: Scalars['Boolean'];
  orderDate: Scalars['String'];
  orderLines: Array<OrderItem>;
  orderedFrom: Scalars['Int'];
  packed: Scalars['Boolean'];
  picked: Scalars['Boolean'];
};

export type OrderErrorEvent = {
  __typename?: 'OrderErrorEvent';
  currentlyActive: Scalars['Boolean'];
  firstErrorTime: Scalars['String'];
};

export type OrderHistoryItem = {
  __typename?: 'OrderHistoryItem';
  automaticOrderDate: Scalars['String'];
  id: Scalars['ID'];
  orderDate: Scalars['String'];
  orderId: Scalars['String'];
};

export type OrderItem = {
  __typename?: 'OrderItem';
  amount: Scalars['Int'];
  amountPerUnit?: Maybe<Scalars['Int']>;
  barcode: Scalars['String'];
  description: Scalars['String'];
  excluded: Scalars['Boolean'];
  id: Scalars['ID'];
  images?: Maybe<Array<Image>>;
  name: Scalars['String'];
  price: Scalars['Float'];
  productNumber: Scalars['String'];
  sorting: Scalars['Int'];
  status: OrderItemStatus;
  taken: Scalars['Int'];
};

export type OrderItemInput = {
  excluded: Scalars['Boolean'];
  id: Scalars['ID'];
  price: Scalars['Float'];
  productNumber: Scalars['String'];
  taken: Scalars['Int'];
};

export enum OrderItemStatus {
  Active = 'ACTIVE',
  Backorder = 'BACKORDER',
  Completed = 'COMPLETED'
}

export type OrderNewItemInput = {
  automaticOrder: AutomaticOrdering;
  excluded: Scalars['Boolean'];
  id: Scalars['ID'];
  maximum: Scalars['Int'];
  minimum: Scalars['Int'];
  price: Scalars['Float'];
  productNumber: Scalars['String'];
  taken: Scalars['Int'];
};

export type Product = {
  __typename?: 'Product';
  created_at?: Maybe<Scalars['String']>;
  customData?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  last_sync?: Maybe<Scalars['String']>;
  prodNo?: Maybe<Scalars['String']>;
  productData?: Maybe<Scalars['JSON']>;
  updated_at?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  accountLogs: Array<AccountLog>;
  accountSettings: AccountSettings;
  accounts: Array<AccountSettings>;
  activeOrders: Array<Order>;
  carts: Array<Maybe<Cart>>;
  frequentManuallyOrderedItems?: Maybe<Scalars['JSON']>;
  getNextActiveOrderingDay?: Maybe<Scalars['JSON']>;
  getProductPrice?: Maybe<Scalars['JSON']>;
  holiday: Holiday;
  holidays: Array<Holiday>;
  inventory: Array<InventoryItem>;
  inventoryIntake: InventoryValue;
  inventoryItem: InventoryItem;
  inventoryItemHistory: Array<InventoryItemHistoryEntry>;
  inventoryOutgoing: InventoryValue;
  inventoryValue: InventoryValue;
  inventoryValueOnDate: Array<InventoryItem>;
  itemListData?: Maybe<Scalars['JSON']>;
  materialValues: Array<MaterialValue>;
  orderHistory: Array<OrderHistoryItem>;
  orders: Array<Order>;
  product: Product;
  products: Array<Product>;
  scans?: Maybe<Scan>;
  totalsValue: Array<TotalsValue>;
  user: User;
  valueChartData: Array<InventoryValueChartData>;
  watchedProducts: Array<WatchedProduct>;
};


export type QueryAccountLogsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryAccountSettingsArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryGetProductPriceArgs = {
  customerId?: InputMaybe<Scalars['String']>;
  prodNo?: InputMaybe<Scalars['String']>;
};


export type QueryHolidayArgs = {
  id: Scalars['String'];
};


export type QueryInventoryIntakeArgs = {
  month?: InputMaybe<Scalars['Int']>;
};


export type QueryInventoryItemArgs = {
  productNumber: Scalars['String'];
};


export type QueryInventoryItemHistoryArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  productNumber: Scalars['String'];
};


export type QueryInventoryOutgoingArgs = {
  month?: InputMaybe<Scalars['Int']>;
};


export type QueryInventoryValueArgs = {
  accountId?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['String']>;
};


export type QueryInventoryValueOnDateArgs = {
  date: Scalars['String'];
};


export type QueryMaterialValuesArgs = {
  accountancyYear?: InputMaybe<Scalars['Int']>;
  period?: InputMaybe<Scalars['String']>;
};


export type QueryOrderHistoryArgs = {
  month: Scalars['Int'];
  year: Scalars['Int'];
};


export type QueryProductArgs = {
  barcode?: InputMaybe<Scalars['String']>;
  productNumber?: InputMaybe<Scalars['String']>;
};


export type QueryProductsArgs = {
  productNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchTerm?: InputMaybe<Scalars['String']>;
};


export type QueryTotalsValueArgs = {
  accountancyYear?: InputMaybe<Scalars['Int']>;
  period?: InputMaybe<Scalars['String']>;
};


export type QueryValueChartDataArgs = {
  startMonth?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type Scan = {
  __typename?: 'Scan';
  accountId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  items: Array<ScanItem>;
};

export type ScanItem = {
  __typename?: 'ScanItem';
  amount: Scalars['Int'];
  barcode: Scalars['String'];
  eventIds: Array<Scalars['String']>;
  eventTime: Scalars['String'];
  id: Scalars['ID'];
  productIds: Array<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  keepAlivePing?: Maybe<Scalars['JSON']>;
  onCartsUpdated: Array<Cart>;
  onInventoryItemAdded: InventoryItem;
  onInventoryItemDeleted: InventoryItem;
  onInventoryItemHistoryUpdated: InventoryItemHistoryEntry;
  onInventoryItemUpdated: InventoryItem;
  onOrderAdded?: Maybe<Order>;
  onOrderDeleted?: Maybe<Order>;
  onOrderError?: Maybe<OrderErrorEvent>;
  onOrderUpdated?: Maybe<Order>;
  onScansUpdated?: Maybe<Scan>;
  onWatchedProductUpdate?: Maybe<WatchedProduct>;
};


export type SubscriptionOnCartsUpdatedArgs = {
  accountId: Scalars['String'];
};


export type SubscriptionOnInventoryItemAddedArgs = {
  accountId: Scalars['String'];
};


export type SubscriptionOnInventoryItemDeletedArgs = {
  accountId: Scalars['String'];
};


export type SubscriptionOnInventoryItemHistoryUpdatedArgs = {
  accountId: Scalars['String'];
};


export type SubscriptionOnInventoryItemUpdatedArgs = {
  accountId: Scalars['String'];
};


export type SubscriptionOnOrderAddedArgs = {
  accountId: Scalars['String'];
};


export type SubscriptionOnOrderDeletedArgs = {
  accountId: Scalars['String'];
};


export type SubscriptionOnOrderUpdatedArgs = {
  accountId: Scalars['String'];
};


export type SubscriptionOnScansUpdatedArgs = {
  accountId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnWatchedProductUpdateArgs = {
  customerId?: InputMaybe<Scalars['String']>;
};

export type TotalsValue = {
  __typename?: 'TotalsValue';
  category?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  percentChange?: Maybe<Scalars['String']>;
  prodNo?: Maybe<Scalars['String']>;
  productData?: Maybe<Scalars['JSON']>;
  value?: Maybe<Scalars['Float']>;
};

export type UpdateAccountInput = {
  accountName?: InputMaybe<Scalars['String']>;
  cvr?: InputMaybe<Scalars['String']>;
  hasLechler?: InputMaybe<Scalars['Boolean']>;
  hasPercotop?: InputMaybe<Scalars['Boolean']>;
  hasSpiesHecker?: InputMaybe<Scalars['Boolean']>;
  hasStandox?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  status?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateAccountSettingsInput = {
  accountancyYear?: InputMaybe<Scalars['Int']>;
  automaticOrderDays?: InputMaybe<Array<InputMaybe<AutomaticOrderDayInput>>>;
  automaticOrderInterval?: InputMaybe<Scalars['String']>;
  countOnScan?: InputMaybe<Scalars['Boolean']>;
  hasAutomaticOrdering?: InputMaybe<Scalars['Boolean']>;
  usersCanEdit?: InputMaybe<Scalars['Boolean']>;
  usersCanExport?: InputMaybe<Scalars['Boolean']>;
};

export type User = {
  __typename?: 'User';
  accountId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['String'];
  lang: Scalars['String'];
  name: Scalars['String'];
  role: Scalars['String'];
  ssoAccountId?: Maybe<Scalars['String']>;
  ssoDistributorId?: Maybe<Scalars['String']>;
};

export type WatchedProduct = {
  __typename?: 'WatchedProduct';
  actionType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  productNumber?: Maybe<Scalars['String']>;
  recommendedMax?: Maybe<Scalars['Int']>;
  recommendedMin?: Maybe<Scalars['Int']>;
  timesOrdered?: Maybe<Scalars['Int']>;
  totalOrdered?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type AddCustomItemMutationVariables = Exact<{
  name: Scalars['String'];
  category: Scalars['String'];
  inStock: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  barcode?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  units?: InputMaybe<Scalars['String']>;
  supplier?: InputMaybe<Scalars['String']>;
}>;


export type AddCustomItemMutation = { __typename?: 'Mutation', addCustomItem: { __typename?: 'InventoryItem', id: string, productNumber: string, accountId: string, category: string, minimum: number, maximum: number, amount: number, automaticOrderingState: AutomaticOrdering, name: string, description: string, status: number, barcode: string, alreadyOrdered: boolean, inCart: boolean, isCustom: boolean, unit: string, amountPerUnit: number, supplier?: string | null, price?: number | null, images: Array<{ __typename?: 'Image', name: string, sorting?: number | null, url: string } | null> } };

export type AddHolidayMutationVariables = Exact<{
  day: Scalars['String'];
  holidayName: Scalars['String'];
}>;


export type AddHolidayMutation = { __typename?: 'Mutation', addHoliday: { __typename?: 'Holiday', id?: string | null, day?: string | null, holidayName?: string | null } };

export type AddInventoryItemMutationVariables = Exact<{
  productNumber: Scalars['String'];
  minimum: Scalars['Int'];
  maximum: Scalars['Int'];
  inStock: Scalars['Int'];
  isCustom: Scalars['Boolean'];
  action: Scalars['String'];
  automaticOrdering?: InputMaybe<AutomaticOrdering>;
  fromOrder?: InputMaybe<Scalars['String']>;
}>;


export type AddInventoryItemMutation = { __typename?: 'Mutation', addInventoryItem: { __typename?: 'InventoryItem', id: string, productNumber: string, accountId: string, category: string, minimum: number, maximum: number, amount: number, automaticOrderingState: AutomaticOrdering, name: string, description: string, status: number, barcode: string, alreadyOrdered: boolean, inCart: boolean, isCustom: boolean, unit: string, amountPerUnit: number, supplier?: string | null, price?: number | null, images: Array<{ __typename?: 'Image', name: string, sorting?: number | null, url: string } | null> } };

export type AddItemsToCartMutationVariables = Exact<{
  items: Array<CartItemInput> | CartItemInput;
}>;


export type AddItemsToCartMutation = { __typename?: 'Mutation', addItemsToCart: Array<{ __typename?: 'Cart', id: string, automaticOrderDate: string, wasDateChanged: boolean, comment?: string | null, orderLines: Array<{ __typename?: 'CartItem', amount: number, productNumber: string, manuallyAdded: boolean, name: string, description?: string | null, images: Array<{ __typename?: 'Image', url: string, name: string, sorting?: number | null }> }> } | null> };

export type AddScannedItemMutationVariables = Exact<{
  barcode: Scalars['String'];
}>;


export type AddScannedItemMutation = { __typename?: 'Mutation', addScannedItem?: { __typename?: 'Scan', id?: string | null, accountId?: string | null, items: Array<{ __typename?: 'ScanItem', id: string, amount: number, barcode: string, eventIds: Array<string>, eventTime: string, productIds: Array<string> }> } | null };

export type CreateAccountMutationVariables = Exact<{
  args: CreateAccountInput;
}>;


export type CreateAccountMutation = { __typename?: 'Mutation', createAccount: { __typename?: 'AccountSettings', id: string, accountName: string, usersCanEdit: boolean, usersCanExport: boolean, countOnScan?: boolean | null, hasAutomaticOrdering?: boolean | null, automaticOrderInterval: string, ignoredProducts?: Array<string | null> | null, hasLechler?: boolean | null, hasSpiesHecker?: boolean | null, hasStandox?: boolean | null, hasPercotop?: boolean | null, isActive?: boolean | null, avoidHolidays?: boolean | null, automaticOrderDays: Array<{ __typename?: 'AutomaticOrderDay', day: string, time: string } | null> } };

export type DeleteInventoryItemMutationVariables = Exact<{
  productNumber: Scalars['String'];
}>;


export type DeleteInventoryItemMutation = { __typename?: 'Mutation', deleteInventoryItem: string };

export type DeleteScannedItemMutationVariables = Exact<{
  scanId: Scalars['String'];
  prodNo: Scalars['String'];
}>;


export type DeleteScannedItemMutation = { __typename?: 'Mutation', deleteScannedItem?: { __typename?: 'Scan', id?: string | null, accountId?: string | null, items: Array<{ __typename?: 'ScanItem', id: string, amount: number, barcode: string, eventIds: Array<string>, eventTime: string, productIds: Array<string> }> } | null };

export type MoveToActiveCartMutationVariables = Exact<{
  cartId: Scalars['String'];
  productNumber: Scalars['String'];
}>;


export type MoveToActiveCartMutation = { __typename?: 'Mutation', moveToActiveCart: Array<{ __typename?: 'Cart', id: string, automaticOrderDate: string, wasDateChanged: boolean, comment?: string | null, orderLines: Array<{ __typename?: 'CartItem', amount: number, productNumber: string, manuallyAdded: boolean, name: string, description?: string | null, images: Array<{ __typename?: 'Image', url: string, name: string, sorting?: number | null }> }> } | null> };

export type OrderCartMutationVariables = Exact<{
  cartId: Scalars['String'];
}>;


export type OrderCartMutation = { __typename?: 'Mutation', orderCart: boolean };

export type RegretInventoryItemHistoryEntryMutationVariables = Exact<{
  regretInventoryItemHistoryEntryId: Scalars['String'];
}>;


export type RegretInventoryItemHistoryEntryMutation = { __typename?: 'Mutation', regretInventoryItemHistoryEntry: { __typename?: 'InventoryItemHistoryEntry', id: string, productIds: Array<string>, eventTime: string, eventType: InventoryItemHistoryEntryEventType, eventAction: InventoryItemHistoryEntryEventAction, productNumber: string, accountId: string, eventReverted: boolean, revertTime?: string | null, fromOrderNumber?: string | null } };

export type RemoveHolidayMutationVariables = Exact<{
  removeHolidayId: Scalars['String'];
}>;


export type RemoveHolidayMutation = { __typename?: 'Mutation', removeHoliday: string };

export type RemoveItemFromCartMutationVariables = Exact<{
  item: CartItemInput;
  moveToNextCart: Scalars['Boolean'];
}>;


export type RemoveItemFromCartMutation = { __typename?: 'Mutation', removeItemFromCart: Array<{ __typename?: 'Cart', id: string, automaticOrderDate: string, wasDateChanged: boolean, comment?: string | null, orderLines: Array<{ __typename?: 'CartItem', amount: number, productNumber: string, manuallyAdded: boolean, name: string, description?: string | null, images: Array<{ __typename?: 'Image', name: string, sorting?: number | null, url: string }> }> }> };

export type SetCartCommentMutationVariables = Exact<{
  cartId: Scalars['String'];
  comment?: InputMaybe<Scalars['String']>;
}>;


export type SetCartCommentMutation = { __typename?: 'Mutation', setCartComment: Array<{ __typename?: 'Cart', id: string, automaticOrderDate: string, wasDateChanged: boolean, comment?: string | null, orderLines: Array<{ __typename?: 'CartItem', amount: number, productNumber: string, manuallyAdded: boolean, name: string, description?: string | null, images: Array<{ __typename?: 'Image', url: string, name: string, sorting?: number | null }> }> }> };

export type UpdateAccountMutationVariables = Exact<{
  args: UpdateAccountInput;
}>;


export type UpdateAccountMutation = { __typename?: 'Mutation', updateAccount: { __typename?: 'AccountSettings', id: string, accountName: string, cvr?: string | null, usersCanEdit: boolean, usersCanExport: boolean, countOnScan?: boolean | null, hasAutomaticOrdering?: boolean | null, automaticOrderInterval: string, ignoredProducts?: Array<string | null> | null, hasLechler?: boolean | null, hasSpiesHecker?: boolean | null, hasStandox?: boolean | null, hasPercotop?: boolean | null, isActive?: boolean | null, avoidHolidays?: boolean | null, automaticOrderDays: Array<{ __typename?: 'AutomaticOrderDay', day: string, time: string } | null> } };

export type UpdateAccountSettingsMutationVariables = Exact<{
  args: UpdateAccountSettingsInput;
}>;


export type UpdateAccountSettingsMutation = { __typename?: 'Mutation', updateAccountSettings: { __typename?: 'AccountSettings', id: string, accountName: string, usersCanEdit: boolean, usersCanExport: boolean, countOnScan?: boolean | null, hasAutomaticOrdering?: boolean | null, automaticOrderInterval: string, ignoredProducts?: Array<string | null> | null, hasLechler?: boolean | null, hasSpiesHecker?: boolean | null, hasStandox?: boolean | null, hasPercotop?: boolean | null, isActive?: boolean | null, avoidHolidays?: boolean | null, automaticOrderDays: Array<{ __typename?: 'AutomaticOrderDay', day: string, time: string } | null> } };

export type UpdateCartItemMutationVariables = Exact<{
  item: CartItemInput;
  cartId: Scalars['String'];
}>;


export type UpdateCartItemMutation = { __typename?: 'Mutation', updateCartItem: Array<{ __typename?: 'Cart', id: string, automaticOrderDate: string, wasDateChanged: boolean, comment?: string | null, orderLines: Array<{ __typename?: 'CartItem', amount: number, productNumber: string, manuallyAdded: boolean, name: string, description?: string | null, images: Array<{ __typename?: 'Image', url: string, name: string, sorting?: number | null }> }> }> };

export type UpdateCustomItemMutationVariables = Exact<{
  updateCustomItemId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  barcode?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  category?: InputMaybe<Scalars['String']>;
  units?: InputMaybe<Scalars['String']>;
  inStock?: InputMaybe<Scalars['Int']>;
  supplier?: InputMaybe<Scalars['String']>;
}>;


export type UpdateCustomItemMutation = { __typename?: 'Mutation', updateCustomItem: { __typename?: 'InventoryItem', id: string, productNumber: string, accountId: string, category: string, minimum: number, maximum: number, amount: number, automaticOrderingState: AutomaticOrdering, name: string, description: string, status: number, barcode: string, alreadyOrdered: boolean, inCart: boolean, isCustom: boolean, unit: string, amountPerUnit: number, supplier?: string | null, price?: number | null, images: Array<{ __typename?: 'Image', url: string, name: string, sorting?: number | null } | null> } };

export type UpdateHolidayMutationVariables = Exact<{
  updateHolidayId: Scalars['String'];
  day?: InputMaybe<Scalars['String']>;
  holidayName?: InputMaybe<Scalars['String']>;
}>;


export type UpdateHolidayMutation = { __typename?: 'Mutation', updateHoliday: { __typename?: 'Holiday', id?: string | null, day?: string | null, holidayName?: string | null } };

export type UpdateInventoryItemMutationVariables = Exact<{
  productNumber: Scalars['String'];
  inStock?: InputMaybe<Scalars['Int']>;
  minimum?: InputMaybe<Scalars['Int']>;
  maximum?: InputMaybe<Scalars['Int']>;
  automaticOrdering?: InputMaybe<AutomaticOrdering>;
  action?: InputMaybe<Scalars['String']>;
}>;


export type UpdateInventoryItemMutation = { __typename?: 'Mutation', updateInventoryItem: { __typename?: 'InventoryItem', id: string, productNumber: string, accountId: string, category: string, minimum: number, maximum: number, amount: number, automaticOrderingState: AutomaticOrdering, name: string, description: string, status: number, barcode: string, alreadyOrdered: boolean, inCart: boolean, isCustom: boolean, unit: string, amountPerUnit: number, supplier?: string | null, price?: number | null, images: Array<{ __typename?: 'Image', url: string, name: string, sorting?: number | null } | null> } };

export type UpdateOrderItemsMutationVariables = Exact<{
  items: Array<OrderItemInput> | OrderItemInput;
  orderId: Scalars['String'];
  newItems: Array<OrderNewItemInput> | OrderNewItemInput;
  withWarningMessage?: InputMaybe<Scalars['String']>;
}>;


export type UpdateOrderItemsMutation = { __typename?: 'Mutation', updateOrderItems: { __typename?: 'Order', id: string, accountId: string, acknowledged: boolean, packed: boolean, picked: boolean, completed: boolean, orderedFrom: number, orderDate: string, comments: Array<string | null>, orderLines: Array<{ __typename?: 'OrderItem', id: string, name: string, description: string, price: number, taken: number, amount: number, productNumber: string, excluded: boolean, barcode: string, status: OrderItemStatus, sorting: number, images?: Array<{ __typename?: 'Image', url: string, name: string, sorting?: number | null }> | null }> } };

export type UpdateScannedItemMutationVariables = Exact<{
  updateScannedItemId: Scalars['String'];
  prodNo: Scalars['String'];
  newAmount: Scalars['Int'];
}>;


export type UpdateScannedItemMutation = { __typename?: 'Mutation', updateScannedItem?: { __typename?: 'Scan', id?: string | null, accountId?: string | null, items: Array<{ __typename?: 'ScanItem', id: string, amount: number, barcode: string, eventIds: Array<string>, eventTime: string, productIds: Array<string> }> } | null };

export type UpdateWatchedProductStatusMutationVariables = Exact<{
  updateWatchedProductStatusId?: InputMaybe<Scalars['String']>;
  response?: InputMaybe<Scalars['String']>;
}>;


export type UpdateWatchedProductStatusMutation = { __typename?: 'Mutation', updateWatchedProductStatus: { __typename?: 'WatchedProduct', id?: string | null, customerId?: string | null, productNumber?: string | null, timesOrdered?: number | null, totalOrdered?: number | null, recommendedMin?: number | null, recommendedMax?: number | null, actionType?: string | null, createdAt?: string | null, updatedAt?: string | null } };

export type AccountLogsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type AccountLogsQuery = { __typename?: 'Query', accountLogs: Array<{ __typename?: 'AccountLog', id: string, accountId: string, message: string, operationType: string, operationGroup: string, extraData?: any | null, createdAt: string }> };

export type AccountSettingsQueryVariables = Exact<{
  accountSettingsId?: InputMaybe<Scalars['String']>;
}>;


export type AccountSettingsQuery = { __typename?: 'Query', accountSettings: { __typename?: 'AccountSettings', accountancyYear?: number | null, id: string, accountName: string, cvr?: string | null, usersCanEdit: boolean, usersCanExport: boolean, countOnScan?: boolean | null, hasAutomaticOrdering?: boolean | null, automaticOrderInterval: string, ignoredProducts?: Array<string | null> | null, hasLechler?: boolean | null, hasSpiesHecker?: boolean | null, hasStandox?: boolean | null, hasPercotop?: boolean | null, isActive?: boolean | null, avoidHolidays?: boolean | null, automaticOrderDays: Array<{ __typename?: 'AutomaticOrderDay', day: string, time: string } | null> } };

export type AccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountsQuery = { __typename?: 'Query', accounts: Array<{ __typename?: 'AccountSettings', id: string, accountName: string, usersCanEdit: boolean, usersCanExport: boolean, countOnScan?: boolean | null, hasAutomaticOrdering?: boolean | null, automaticOrderInterval: string, ignoredProducts?: Array<string | null> | null, hasLechler?: boolean | null, hasSpiesHecker?: boolean | null, hasStandox?: boolean | null, hasPercotop?: boolean | null, isActive?: boolean | null, avoidHolidays?: boolean | null, automaticOrderDays: Array<{ __typename?: 'AutomaticOrderDay', day: string, time: string } | null> }> };

export type ActiveOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveOrdersQuery = { __typename?: 'Query', activeOrders: Array<{ __typename?: 'Order', id: string, accountId: string, acknowledged: boolean, packed: boolean, picked: boolean, completed: boolean, orderedFrom: number, orderDate: string, comments: Array<string | null>, backorder: boolean, invoiced: boolean, deliveryId?: string | null, cancelled: boolean, orderLines: Array<{ __typename?: 'OrderItem', id: string, name: string, description: string, price: number, taken: number, amount: number, productNumber: string, excluded: boolean, barcode: string, status: OrderItemStatus, sorting: number, amountPerUnit?: number | null, images?: Array<{ __typename?: 'Image', name: string, sorting?: number | null, url: string }> | null }> }> };

export type CartsQueryVariables = Exact<{ [key: string]: never; }>;


export type CartsQuery = { __typename?: 'Query', carts: Array<{ __typename?: 'Cart', id: string, automaticOrderDate: string, wasDateChanged: boolean, comment?: string | null, orderLines: Array<{ __typename?: 'CartItem', amount: number, description?: string | null, manuallyAdded: boolean, name: string, productNumber: string, images: Array<{ __typename?: 'Image', name: string, sorting?: number | null, url: string }> }> } | null> };

export type QueryQueryVariables = Exact<{ [key: string]: never; }>;


export type QueryQuery = { __typename?: 'Query', frequentManuallyOrderedItems?: any | null };

export type HolidayQueryVariables = Exact<{
  holidayId: Scalars['String'];
}>;


export type HolidayQuery = { __typename?: 'Query', holiday: { __typename?: 'Holiday', id?: string | null, day?: string | null, holidayName?: string | null } };

export type HolidaysQueryVariables = Exact<{ [key: string]: never; }>;


export type HolidaysQuery = { __typename?: 'Query', holidays: Array<{ __typename?: 'Holiday', id?: string | null, day?: string | null, holidayName?: string | null }> };

export type InventoryValueQueryVariables = Exact<{
  start?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['String']>;
}>;


export type InventoryValueQuery = { __typename?: 'Query', inventoryValue: { __typename?: 'InventoryValue', currentValue?: number | null, changeDirection?: string | null, changePercent?: number | null } };

export type InventoryQueryVariables = Exact<{ [key: string]: never; }>;


export type InventoryQuery = { __typename?: 'Query', inventory: Array<{ __typename?: 'InventoryItem', id: string, productNumber: string, accountId: string, category: string, minimum: number, maximum: number, amount: number, automaticOrderingState: AutomaticOrdering, name: string, description: string, status: number, barcode: string, alreadyOrdered: boolean, inCart: boolean, isCustom: boolean, unit: string, amountPerUnit: number, supplier?: string | null, price?: number | null, images: Array<{ __typename?: 'Image', url: string, name: string, sorting?: number | null } | null> }> };

export type InventoryItemQueryVariables = Exact<{
  productNumber: Scalars['String'];
}>;


export type InventoryItemQuery = { __typename?: 'Query', inventoryItem: { __typename?: 'InventoryItem', id: string, accountId: string, alreadyOrdered: boolean, amount: number, amountPerUnit: number, automaticOrderingState: AutomaticOrdering, barcode: string, category: string, description: string, inCart: boolean, isCustom: boolean, maximum: number, minimum: number, name: string, price?: number | null, productNumber: string, status: number, supplier?: string | null, unit: string, images: Array<{ __typename?: 'Image', name: string, sorting?: number | null, url: string } | null> } };

export type InventoryIntakeQueryVariables = Exact<{
  month?: InputMaybe<Scalars['Int']>;
}>;


export type InventoryIntakeQuery = { __typename?: 'Query', inventoryIntake: { __typename?: 'InventoryValue', currentValue?: number | null, changeDirection?: string | null, changePercent?: number | null } };

export type InventoryItemHistoryQueryVariables = Exact<{
  productNumber: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type InventoryItemHistoryQuery = { __typename?: 'Query', inventoryItemHistory: Array<{ __typename?: 'InventoryItemHistoryEntry', id: string, productIds: Array<string>, eventTime: string, eventType: InventoryItemHistoryEntryEventType, eventAction: InventoryItemHistoryEntryEventAction, productNumber: string, accountId: string, eventReverted: boolean, revertTime?: string | null, fromOrderNumber?: string | null }> };

export type InventoryOutgoingQueryVariables = Exact<{
  month?: InputMaybe<Scalars['Int']>;
}>;


export type InventoryOutgoingQuery = { __typename?: 'Query', inventoryOutgoing: { __typename?: 'InventoryValue', currentValue?: number | null, changeDirection?: string | null, changePercent?: number | null } };

export type InventoryValueOnDateQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type InventoryValueOnDateQuery = { __typename?: 'Query', inventoryValueOnDate: Array<{ __typename?: 'InventoryItem', id: string, productNumber: string, accountId: string, category: string, minimum: number, maximum: number, amount: number, automaticOrderingState: AutomaticOrdering, name: string, description: string, status: number, barcode: string, alreadyOrdered: boolean, inCart: boolean, isCustom: boolean, unit: string, amountPerUnit: number, supplier?: string | null, price?: number | null, images: Array<{ __typename?: 'Image', url: string, name: string, sorting?: number | null } | null> }> };

export type ItemListDataQueryVariables = Exact<{ [key: string]: never; }>;


export type ItemListDataQuery = { __typename?: 'Query', itemListData?: any | null };

export type MaterialValuesQueryVariables = Exact<{
  period?: InputMaybe<Scalars['String']>;
  accountancyYear?: InputMaybe<Scalars['Int']>;
}>;


export type MaterialValuesQuery = { __typename?: 'Query', materialValues: Array<{ __typename?: 'MaterialValue', id?: string | null, value?: number | null, percentChange?: number | null, prodNo?: string | null, productData?: any | null, category?: string | null }> };

export type OrderHistoryQueryVariables = Exact<{
  month: Scalars['Int'];
  year: Scalars['Int'];
}>;


export type OrderHistoryQuery = { __typename?: 'Query', orderHistory: Array<{ __typename?: 'OrderHistoryItem', id: string, orderId: string, automaticOrderDate: string, orderDate: string }> };

export type OrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type OrdersQuery = { __typename?: 'Query', orders: Array<{ __typename?: 'Order', id: string, accountId: string, acknowledged: boolean, packed: boolean, picked: boolean, completed: boolean, orderedFrom: number, orderDate: string, comments: Array<string | null>, backorder: boolean, invoiced: boolean, deliveryId?: string | null, cancelled: boolean, orderLines: Array<{ __typename?: 'OrderItem', id: string, name: string, description: string, price: number, taken: number, amount: number, productNumber: string, excluded: boolean, barcode: string, status: OrderItemStatus, sorting: number, amountPerUnit?: number | null, images?: Array<{ __typename?: 'Image', name: string, sorting?: number | null, url: string }> | null }> }> };

export type ProductQueryVariables = Exact<{
  barcode?: InputMaybe<Scalars['String']>;
  productNumber?: InputMaybe<Scalars['String']>;
}>;


export type ProductQuery = { __typename?: 'Query', product: { __typename?: 'Product', id: string, prodNo?: string | null, productData?: any | null, customData?: any | null, updated_by?: string | null, created_at?: string | null, updated_at?: string | null, last_sync?: string | null } };

export type ProductsQueryVariables = Exact<{
  searchTerm?: InputMaybe<Scalars['String']>;
  productNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type ProductsQuery = { __typename?: 'Query', products: Array<{ __typename?: 'Product', id: string, prodNo?: string | null, productData?: any | null, customData?: any | null, updated_by?: string | null, created_at?: string | null, updated_at?: string | null, last_sync?: string | null }> };

export type ScansQueryVariables = Exact<{ [key: string]: never; }>;


export type ScansQuery = { __typename?: 'Query', scans?: { __typename?: 'Scan', id?: string | null, accountId?: string | null, items: Array<{ __typename?: 'ScanItem', id: string, amount: number, barcode: string, eventIds: Array<string>, eventTime: string, productIds: Array<string> }> } | null };

export type TotalsValueQueryVariables = Exact<{
  period?: InputMaybe<Scalars['String']>;
  accountancyYear?: InputMaybe<Scalars['Int']>;
}>;


export type TotalsValueQuery = { __typename?: 'Query', totalsValue: Array<{ __typename?: 'TotalsValue', id?: string | null, value?: number | null, name?: string | null, percentChange?: string | null, prodNo?: string | null, productData?: any | null, category?: string | null }> };

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, email: string, accountId?: string | null, role: string, name: string, lang: string, ssoAccountId?: string | null, ssoDistributorId?: string | null } };

export type ValueChartDataQueryVariables = Exact<{
  year?: InputMaybe<Scalars['Int']>;
  startMonth?: InputMaybe<Scalars['Int']>;
}>;


export type ValueChartDataQuery = { __typename?: 'Query', valueChartData: Array<{ __typename?: 'InventoryValueChartData', name?: string | null, inventoryValue?: number | null, intakeValue?: number | null, outgoingValue?: number | null, year?: number | null }> };

export type WatchedProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type WatchedProductsQuery = { __typename?: 'Query', watchedProducts: Array<{ __typename?: 'WatchedProduct', id?: string | null, customerId?: string | null, productNumber?: string | null, timesOrdered?: number | null, totalOrdered?: number | null, recommendedMin?: number | null, recommendedMax?: number | null, actionType?: string | null, createdAt?: string | null, updatedAt?: string | null }> };

export type KeepAliveSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type KeepAliveSubscription = { __typename?: 'Subscription', keepAlivePing?: any | null };

export type OnCartsUpdatedSubscriptionVariables = Exact<{
  accountId: Scalars['String'];
}>;


export type OnCartsUpdatedSubscription = { __typename?: 'Subscription', onCartsUpdated: Array<{ __typename?: 'Cart', id: string, automaticOrderDate: string, wasDateChanged: boolean, comment?: string | null, orderLines: Array<{ __typename?: 'CartItem', amount: number, productNumber: string, manuallyAdded: boolean, name: string, description?: string | null, images: Array<{ __typename?: 'Image', url: string, name: string, sorting?: number | null }> }> }> };

export type OnInventoryItemAddedSubscriptionVariables = Exact<{
  accountId: Scalars['String'];
}>;


export type OnInventoryItemAddedSubscription = { __typename?: 'Subscription', onInventoryItemAdded: { __typename?: 'InventoryItem', id: string, productNumber: string, accountId: string, category: string, minimum: number, maximum: number, amount: number, automaticOrderingState: AutomaticOrdering, name: string, description: string, status: number, barcode: string, alreadyOrdered: boolean, inCart: boolean, isCustom: boolean, unit: string, amountPerUnit: number, supplier?: string | null, price?: number | null, images: Array<{ __typename?: 'Image', url: string, name: string, sorting?: number | null } | null> } };

export type OnInventoryItemDeletedSubscriptionVariables = Exact<{
  accountId: Scalars['String'];
}>;


export type OnInventoryItemDeletedSubscription = { __typename?: 'Subscription', onInventoryItemDeleted: { __typename?: 'InventoryItem', id: string, productNumber: string, accountId: string, category: string, minimum: number, maximum: number, amount: number, automaticOrderingState: AutomaticOrdering, name: string, description: string, status: number, barcode: string, alreadyOrdered: boolean, inCart: boolean, isCustom: boolean, unit: string, amountPerUnit: number, supplier?: string | null, price?: number | null, images: Array<{ __typename?: 'Image', url: string, name: string, sorting?: number | null } | null> } };

export type OnInventoryItemHistoryUpdatedSubscriptionVariables = Exact<{
  accountId: Scalars['String'];
}>;


export type OnInventoryItemHistoryUpdatedSubscription = { __typename?: 'Subscription', onInventoryItemHistoryUpdated: { __typename?: 'InventoryItemHistoryEntry', id: string, productIds: Array<string>, eventTime: string, eventType: InventoryItemHistoryEntryEventType, eventAction: InventoryItemHistoryEntryEventAction, productNumber: string, accountId: string, eventReverted: boolean, revertTime?: string | null, fromOrderNumber?: string | null } };

export type OnInventoryItemUpdatedSubscriptionVariables = Exact<{
  accountId: Scalars['String'];
}>;


export type OnInventoryItemUpdatedSubscription = { __typename?: 'Subscription', onInventoryItemUpdated: { __typename?: 'InventoryItem', id: string, productNumber: string, accountId: string, category: string, minimum: number, maximum: number, amount: number, automaticOrderingState: AutomaticOrdering, name: string, description: string, status: number, barcode: string, alreadyOrdered: boolean, inCart: boolean, isCustom: boolean, unit: string, amountPerUnit: number, supplier?: string | null, price?: number | null, images: Array<{ __typename?: 'Image', url: string, name: string, sorting?: number | null } | null> } };

export type OnOrderAddedSubscriptionVariables = Exact<{
  accountId: Scalars['String'];
}>;


export type OnOrderAddedSubscription = { __typename?: 'Subscription', onOrderAdded?: { __typename?: 'Order', id: string, accountId: string, acknowledged: boolean, packed: boolean, picked: boolean, completed: boolean, orderedFrom: number, orderDate: string, comments: Array<string | null>, orderLines: Array<{ __typename?: 'OrderItem', id: string, status: OrderItemStatus, name: string, description: string, price: number, taken: number, amount: number, productNumber: string, excluded: boolean, barcode: string, images?: Array<{ __typename?: 'Image', url: string, name: string, sorting?: number | null }> | null }> } | null };

export type OnOrderDeletedSubscriptionVariables = Exact<{
  accountId: Scalars['String'];
}>;


export type OnOrderDeletedSubscription = { __typename?: 'Subscription', onOrderDeleted?: { __typename?: 'Order', id: string, accountId: string, acknowledged: boolean, packed: boolean, picked: boolean, completed: boolean, orderedFrom: number, orderDate: string, comments: Array<string | null>, orderLines: Array<{ __typename?: 'OrderItem', id: string, status: OrderItemStatus, name: string, description: string, price: number, taken: number, amount: number, productNumber: string, excluded: boolean, barcode: string, images?: Array<{ __typename?: 'Image', url: string, name: string, sorting?: number | null }> | null }> } | null };

export type OnOrderErrorSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnOrderErrorSubscription = { __typename?: 'Subscription', onOrderError?: { __typename?: 'OrderErrorEvent', firstErrorTime: string, currentlyActive: boolean } | null };

export type OnOrderUpdatedSubscriptionVariables = Exact<{
  accountId: Scalars['String'];
}>;


export type OnOrderUpdatedSubscription = { __typename?: 'Subscription', onOrderUpdated?: { __typename?: 'Order', id: string, accountId: string, acknowledged: boolean, packed: boolean, picked: boolean, completed: boolean, orderedFrom: number, orderDate: string, comments: Array<string | null>, orderLines: Array<{ __typename?: 'OrderItem', id: string, status: OrderItemStatus, name: string, description: string, price: number, taken: number, amount: number, productNumber: string, excluded: boolean, barcode: string, sorting: number, images?: Array<{ __typename?: 'Image', url: string, name: string, sorting?: number | null }> | null }> } | null };

export type OnScansUpdatedSubscriptionVariables = Exact<{
  accountId?: InputMaybe<Scalars['String']>;
}>;


export type OnScansUpdatedSubscription = { __typename?: 'Subscription', onScansUpdated?: { __typename?: 'Scan', id?: string | null, accountId?: string | null, items: Array<{ __typename?: 'ScanItem', id: string, amount: number, barcode: string, eventIds: Array<string>, eventTime: string, productIds: Array<string> }> } | null };


export const AddCustomItemDocument = gql`
    mutation AddCustomItem($name: String!, $category: String!, $inStock: Int!, $description: String, $barcode: String, $price: Int, $units: String, $supplier: String) {
  addCustomItem(
    name: $name
    category: $category
    inStock: $inStock
    description: $description
    barcode: $barcode
    price: $price
    units: $units
    supplier: $supplier
  ) {
    id
    productNumber
    accountId
    category
    minimum
    maximum
    amount
    automaticOrderingState
    name
    description
    status
    barcode
    images {
      name
      sorting
      url
    }
    alreadyOrdered
    inCart
    isCustom
    unit
    amountPerUnit
    supplier
    price
  }
}
    `;
export type AddCustomItemMutationFn = Apollo.MutationFunction<AddCustomItemMutation, AddCustomItemMutationVariables>;

/**
 * __useAddCustomItemMutation__
 *
 * To run a mutation, you first call `useAddCustomItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCustomItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCustomItemMutation, { data, loading, error }] = useAddCustomItemMutation({
 *   variables: {
 *      name: // value for 'name'
 *      category: // value for 'category'
 *      inStock: // value for 'inStock'
 *      description: // value for 'description'
 *      barcode: // value for 'barcode'
 *      price: // value for 'price'
 *      units: // value for 'units'
 *      supplier: // value for 'supplier'
 *   },
 * });
 */
export function useAddCustomItemMutation(baseOptions?: Apollo.MutationHookOptions<AddCustomItemMutation, AddCustomItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCustomItemMutation, AddCustomItemMutationVariables>(AddCustomItemDocument, options);
      }
export type AddCustomItemMutationHookResult = ReturnType<typeof useAddCustomItemMutation>;
export type AddCustomItemMutationResult = Apollo.MutationResult<AddCustomItemMutation>;
export type AddCustomItemMutationOptions = Apollo.BaseMutationOptions<AddCustomItemMutation, AddCustomItemMutationVariables>;
export const AddHolidayDocument = gql`
    mutation AddHoliday($day: String!, $holidayName: String!) {
  addHoliday(day: $day, holiday_name: $holidayName) {
    id
    day
    holidayName
  }
}
    `;
export type AddHolidayMutationFn = Apollo.MutationFunction<AddHolidayMutation, AddHolidayMutationVariables>;

/**
 * __useAddHolidayMutation__
 *
 * To run a mutation, you first call `useAddHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addHolidayMutation, { data, loading, error }] = useAddHolidayMutation({
 *   variables: {
 *      day: // value for 'day'
 *      holidayName: // value for 'holidayName'
 *   },
 * });
 */
export function useAddHolidayMutation(baseOptions?: Apollo.MutationHookOptions<AddHolidayMutation, AddHolidayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddHolidayMutation, AddHolidayMutationVariables>(AddHolidayDocument, options);
      }
export type AddHolidayMutationHookResult = ReturnType<typeof useAddHolidayMutation>;
export type AddHolidayMutationResult = Apollo.MutationResult<AddHolidayMutation>;
export type AddHolidayMutationOptions = Apollo.BaseMutationOptions<AddHolidayMutation, AddHolidayMutationVariables>;
export const AddInventoryItemDocument = gql`
    mutation AddInventoryItem($productNumber: String!, $minimum: Int!, $maximum: Int!, $inStock: Int!, $isCustom: Boolean!, $action: String!, $automaticOrdering: AutomaticOrdering, $fromOrder: String) {
  addInventoryItem(
    productNumber: $productNumber
    minimum: $minimum
    maximum: $maximum
    inStock: $inStock
    isCustom: $isCustom
    action: $action
    automaticOrdering: $automaticOrdering
    fromOrder: $fromOrder
  ) {
    id
    productNumber
    accountId
    category
    minimum
    maximum
    amount
    automaticOrderingState
    name
    description
    status
    barcode
    images {
      name
      sorting
      url
    }
    alreadyOrdered
    inCart
    isCustom
    unit
    amountPerUnit
    supplier
    price
  }
}
    `;
export type AddInventoryItemMutationFn = Apollo.MutationFunction<AddInventoryItemMutation, AddInventoryItemMutationVariables>;

/**
 * __useAddInventoryItemMutation__
 *
 * To run a mutation, you first call `useAddInventoryItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInventoryItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInventoryItemMutation, { data, loading, error }] = useAddInventoryItemMutation({
 *   variables: {
 *      productNumber: // value for 'productNumber'
 *      minimum: // value for 'minimum'
 *      maximum: // value for 'maximum'
 *      inStock: // value for 'inStock'
 *      isCustom: // value for 'isCustom'
 *      action: // value for 'action'
 *      automaticOrdering: // value for 'automaticOrdering'
 *      fromOrder: // value for 'fromOrder'
 *   },
 * });
 */
export function useAddInventoryItemMutation(baseOptions?: Apollo.MutationHookOptions<AddInventoryItemMutation, AddInventoryItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInventoryItemMutation, AddInventoryItemMutationVariables>(AddInventoryItemDocument, options);
      }
export type AddInventoryItemMutationHookResult = ReturnType<typeof useAddInventoryItemMutation>;
export type AddInventoryItemMutationResult = Apollo.MutationResult<AddInventoryItemMutation>;
export type AddInventoryItemMutationOptions = Apollo.BaseMutationOptions<AddInventoryItemMutation, AddInventoryItemMutationVariables>;
export const AddItemsToCartDocument = gql`
    mutation AddItemsToCart($items: [CartItemInput!]!) {
  addItemsToCart(items: $items) {
    id
    orderLines {
      amount
      productNumber
      manuallyAdded
      name
      description
      images {
        url
        name
        sorting
      }
    }
    automaticOrderDate
    wasDateChanged
    comment
  }
}
    `;
export type AddItemsToCartMutationFn = Apollo.MutationFunction<AddItemsToCartMutation, AddItemsToCartMutationVariables>;

/**
 * __useAddItemsToCartMutation__
 *
 * To run a mutation, you first call `useAddItemsToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddItemsToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addItemsToCartMutation, { data, loading, error }] = useAddItemsToCartMutation({
 *   variables: {
 *      items: // value for 'items'
 *   },
 * });
 */
export function useAddItemsToCartMutation(baseOptions?: Apollo.MutationHookOptions<AddItemsToCartMutation, AddItemsToCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddItemsToCartMutation, AddItemsToCartMutationVariables>(AddItemsToCartDocument, options);
      }
export type AddItemsToCartMutationHookResult = ReturnType<typeof useAddItemsToCartMutation>;
export type AddItemsToCartMutationResult = Apollo.MutationResult<AddItemsToCartMutation>;
export type AddItemsToCartMutationOptions = Apollo.BaseMutationOptions<AddItemsToCartMutation, AddItemsToCartMutationVariables>;
export const AddScannedItemDocument = gql`
    mutation AddScannedItem($barcode: String!) {
  addScannedItem(barcode: $barcode) {
    id
    accountId
    items {
      id
      amount
      barcode
      eventIds
      eventTime
      productIds
    }
  }
}
    `;
export type AddScannedItemMutationFn = Apollo.MutationFunction<AddScannedItemMutation, AddScannedItemMutationVariables>;

/**
 * __useAddScannedItemMutation__
 *
 * To run a mutation, you first call `useAddScannedItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddScannedItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addScannedItemMutation, { data, loading, error }] = useAddScannedItemMutation({
 *   variables: {
 *      barcode: // value for 'barcode'
 *   },
 * });
 */
export function useAddScannedItemMutation(baseOptions?: Apollo.MutationHookOptions<AddScannedItemMutation, AddScannedItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddScannedItemMutation, AddScannedItemMutationVariables>(AddScannedItemDocument, options);
      }
export type AddScannedItemMutationHookResult = ReturnType<typeof useAddScannedItemMutation>;
export type AddScannedItemMutationResult = Apollo.MutationResult<AddScannedItemMutation>;
export type AddScannedItemMutationOptions = Apollo.BaseMutationOptions<AddScannedItemMutation, AddScannedItemMutationVariables>;
export const CreateAccountDocument = gql`
    mutation CreateAccount($args: CreateAccountInput!) {
  createAccount(args: $args) {
    id
    accountName
    usersCanEdit
    usersCanExport
    countOnScan
    hasAutomaticOrdering
    automaticOrderDays {
      day
      time
    }
    automaticOrderInterval
    ignoredProducts
    hasLechler
    hasSpiesHecker
    hasStandox
    hasPercotop
    isActive
    avoidHolidays
  }
}
    `;
export type CreateAccountMutationFn = Apollo.MutationFunction<CreateAccountMutation, CreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCreateAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, options);
      }
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<CreateAccountMutation, CreateAccountMutationVariables>;
export const DeleteInventoryItemDocument = gql`
    mutation DeleteInventoryItem($productNumber: String!) {
  deleteInventoryItem(productNumber: $productNumber)
}
    `;
export type DeleteInventoryItemMutationFn = Apollo.MutationFunction<DeleteInventoryItemMutation, DeleteInventoryItemMutationVariables>;

/**
 * __useDeleteInventoryItemMutation__
 *
 * To run a mutation, you first call `useDeleteInventoryItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInventoryItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInventoryItemMutation, { data, loading, error }] = useDeleteInventoryItemMutation({
 *   variables: {
 *      productNumber: // value for 'productNumber'
 *   },
 * });
 */
export function useDeleteInventoryItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInventoryItemMutation, DeleteInventoryItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInventoryItemMutation, DeleteInventoryItemMutationVariables>(DeleteInventoryItemDocument, options);
      }
export type DeleteInventoryItemMutationHookResult = ReturnType<typeof useDeleteInventoryItemMutation>;
export type DeleteInventoryItemMutationResult = Apollo.MutationResult<DeleteInventoryItemMutation>;
export type DeleteInventoryItemMutationOptions = Apollo.BaseMutationOptions<DeleteInventoryItemMutation, DeleteInventoryItemMutationVariables>;
export const DeleteScannedItemDocument = gql`
    mutation DeleteScannedItem($scanId: String!, $prodNo: String!) {
  deleteScannedItem(scanId: $scanId, prodNo: $prodNo) {
    id
    accountId
    items {
      id
      amount
      barcode
      eventIds
      eventTime
      productIds
    }
  }
}
    `;
export type DeleteScannedItemMutationFn = Apollo.MutationFunction<DeleteScannedItemMutation, DeleteScannedItemMutationVariables>;

/**
 * __useDeleteScannedItemMutation__
 *
 * To run a mutation, you first call `useDeleteScannedItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScannedItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScannedItemMutation, { data, loading, error }] = useDeleteScannedItemMutation({
 *   variables: {
 *      scanId: // value for 'scanId'
 *      prodNo: // value for 'prodNo'
 *   },
 * });
 */
export function useDeleteScannedItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteScannedItemMutation, DeleteScannedItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteScannedItemMutation, DeleteScannedItemMutationVariables>(DeleteScannedItemDocument, options);
      }
export type DeleteScannedItemMutationHookResult = ReturnType<typeof useDeleteScannedItemMutation>;
export type DeleteScannedItemMutationResult = Apollo.MutationResult<DeleteScannedItemMutation>;
export type DeleteScannedItemMutationOptions = Apollo.BaseMutationOptions<DeleteScannedItemMutation, DeleteScannedItemMutationVariables>;
export const MoveToActiveCartDocument = gql`
    mutation MoveToActiveCart($cartId: String!, $productNumber: String!) {
  moveToActiveCart(cartId: $cartId, productNumber: $productNumber) {
    id
    orderLines {
      amount
      productNumber
      manuallyAdded
      name
      description
      images {
        url
        name
        sorting
      }
    }
    automaticOrderDate
    wasDateChanged
    comment
  }
}
    `;
export type MoveToActiveCartMutationFn = Apollo.MutationFunction<MoveToActiveCartMutation, MoveToActiveCartMutationVariables>;

/**
 * __useMoveToActiveCartMutation__
 *
 * To run a mutation, you first call `useMoveToActiveCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveToActiveCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveToActiveCartMutation, { data, loading, error }] = useMoveToActiveCartMutation({
 *   variables: {
 *      cartId: // value for 'cartId'
 *      productNumber: // value for 'productNumber'
 *   },
 * });
 */
export function useMoveToActiveCartMutation(baseOptions?: Apollo.MutationHookOptions<MoveToActiveCartMutation, MoveToActiveCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveToActiveCartMutation, MoveToActiveCartMutationVariables>(MoveToActiveCartDocument, options);
      }
export type MoveToActiveCartMutationHookResult = ReturnType<typeof useMoveToActiveCartMutation>;
export type MoveToActiveCartMutationResult = Apollo.MutationResult<MoveToActiveCartMutation>;
export type MoveToActiveCartMutationOptions = Apollo.BaseMutationOptions<MoveToActiveCartMutation, MoveToActiveCartMutationVariables>;
export const OrderCartDocument = gql`
    mutation OrderCart($cartId: String!) {
  orderCart(cartId: $cartId)
}
    `;
export type OrderCartMutationFn = Apollo.MutationFunction<OrderCartMutation, OrderCartMutationVariables>;

/**
 * __useOrderCartMutation__
 *
 * To run a mutation, you first call `useOrderCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderCartMutation, { data, loading, error }] = useOrderCartMutation({
 *   variables: {
 *      cartId: // value for 'cartId'
 *   },
 * });
 */
export function useOrderCartMutation(baseOptions?: Apollo.MutationHookOptions<OrderCartMutation, OrderCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderCartMutation, OrderCartMutationVariables>(OrderCartDocument, options);
      }
export type OrderCartMutationHookResult = ReturnType<typeof useOrderCartMutation>;
export type OrderCartMutationResult = Apollo.MutationResult<OrderCartMutation>;
export type OrderCartMutationOptions = Apollo.BaseMutationOptions<OrderCartMutation, OrderCartMutationVariables>;
export const RegretInventoryItemHistoryEntryDocument = gql`
    mutation RegretInventoryItemHistoryEntry($regretInventoryItemHistoryEntryId: String!) {
  regretInventoryItemHistoryEntry(id: $regretInventoryItemHistoryEntryId) {
    id
    productIds
    eventTime
    eventType
    eventAction
    productNumber
    accountId
    eventReverted
    revertTime
    fromOrderNumber
  }
}
    `;
export type RegretInventoryItemHistoryEntryMutationFn = Apollo.MutationFunction<RegretInventoryItemHistoryEntryMutation, RegretInventoryItemHistoryEntryMutationVariables>;

/**
 * __useRegretInventoryItemHistoryEntryMutation__
 *
 * To run a mutation, you first call `useRegretInventoryItemHistoryEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegretInventoryItemHistoryEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regretInventoryItemHistoryEntryMutation, { data, loading, error }] = useRegretInventoryItemHistoryEntryMutation({
 *   variables: {
 *      regretInventoryItemHistoryEntryId: // value for 'regretInventoryItemHistoryEntryId'
 *   },
 * });
 */
export function useRegretInventoryItemHistoryEntryMutation(baseOptions?: Apollo.MutationHookOptions<RegretInventoryItemHistoryEntryMutation, RegretInventoryItemHistoryEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegretInventoryItemHistoryEntryMutation, RegretInventoryItemHistoryEntryMutationVariables>(RegretInventoryItemHistoryEntryDocument, options);
      }
export type RegretInventoryItemHistoryEntryMutationHookResult = ReturnType<typeof useRegretInventoryItemHistoryEntryMutation>;
export type RegretInventoryItemHistoryEntryMutationResult = Apollo.MutationResult<RegretInventoryItemHistoryEntryMutation>;
export type RegretInventoryItemHistoryEntryMutationOptions = Apollo.BaseMutationOptions<RegretInventoryItemHistoryEntryMutation, RegretInventoryItemHistoryEntryMutationVariables>;
export const RemoveHolidayDocument = gql`
    mutation RemoveHoliday($removeHolidayId: String!) {
  removeHoliday(id: $removeHolidayId)
}
    `;
export type RemoveHolidayMutationFn = Apollo.MutationFunction<RemoveHolidayMutation, RemoveHolidayMutationVariables>;

/**
 * __useRemoveHolidayMutation__
 *
 * To run a mutation, you first call `useRemoveHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeHolidayMutation, { data, loading, error }] = useRemoveHolidayMutation({
 *   variables: {
 *      removeHolidayId: // value for 'removeHolidayId'
 *   },
 * });
 */
export function useRemoveHolidayMutation(baseOptions?: Apollo.MutationHookOptions<RemoveHolidayMutation, RemoveHolidayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveHolidayMutation, RemoveHolidayMutationVariables>(RemoveHolidayDocument, options);
      }
export type RemoveHolidayMutationHookResult = ReturnType<typeof useRemoveHolidayMutation>;
export type RemoveHolidayMutationResult = Apollo.MutationResult<RemoveHolidayMutation>;
export type RemoveHolidayMutationOptions = Apollo.BaseMutationOptions<RemoveHolidayMutation, RemoveHolidayMutationVariables>;
export const RemoveItemFromCartDocument = gql`
    mutation RemoveItemFromCart($item: CartItemInput!, $moveToNextCart: Boolean!) {
  removeItemFromCart(item: $item, moveToNextCart: $moveToNextCart) {
    id
    orderLines {
      amount
      productNumber
      manuallyAdded
      name
      description
      images {
        name
        sorting
        url
      }
    }
    automaticOrderDate
    wasDateChanged
    comment
  }
}
    `;
export type RemoveItemFromCartMutationFn = Apollo.MutationFunction<RemoveItemFromCartMutation, RemoveItemFromCartMutationVariables>;

/**
 * __useRemoveItemFromCartMutation__
 *
 * To run a mutation, you first call `useRemoveItemFromCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveItemFromCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeItemFromCartMutation, { data, loading, error }] = useRemoveItemFromCartMutation({
 *   variables: {
 *      item: // value for 'item'
 *      moveToNextCart: // value for 'moveToNextCart'
 *   },
 * });
 */
export function useRemoveItemFromCartMutation(baseOptions?: Apollo.MutationHookOptions<RemoveItemFromCartMutation, RemoveItemFromCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveItemFromCartMutation, RemoveItemFromCartMutationVariables>(RemoveItemFromCartDocument, options);
      }
export type RemoveItemFromCartMutationHookResult = ReturnType<typeof useRemoveItemFromCartMutation>;
export type RemoveItemFromCartMutationResult = Apollo.MutationResult<RemoveItemFromCartMutation>;
export type RemoveItemFromCartMutationOptions = Apollo.BaseMutationOptions<RemoveItemFromCartMutation, RemoveItemFromCartMutationVariables>;
export const SetCartCommentDocument = gql`
    mutation SetCartComment($cartId: String!, $comment: String) {
  setCartComment(cartId: $cartId, comment: $comment) {
    id
    orderLines {
      amount
      productNumber
      manuallyAdded
      name
      description
      images {
        url
        name
        sorting
      }
    }
    automaticOrderDate
    wasDateChanged
    comment
  }
}
    `;
export type SetCartCommentMutationFn = Apollo.MutationFunction<SetCartCommentMutation, SetCartCommentMutationVariables>;

/**
 * __useSetCartCommentMutation__
 *
 * To run a mutation, you first call `useSetCartCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCartCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCartCommentMutation, { data, loading, error }] = useSetCartCommentMutation({
 *   variables: {
 *      cartId: // value for 'cartId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useSetCartCommentMutation(baseOptions?: Apollo.MutationHookOptions<SetCartCommentMutation, SetCartCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCartCommentMutation, SetCartCommentMutationVariables>(SetCartCommentDocument, options);
      }
export type SetCartCommentMutationHookResult = ReturnType<typeof useSetCartCommentMutation>;
export type SetCartCommentMutationResult = Apollo.MutationResult<SetCartCommentMutation>;
export type SetCartCommentMutationOptions = Apollo.BaseMutationOptions<SetCartCommentMutation, SetCartCommentMutationVariables>;
export const UpdateAccountDocument = gql`
    mutation UpdateAccount($args: UpdateAccountInput!) {
  updateAccount(args: $args) {
    id
    accountName
    cvr
    usersCanEdit
    usersCanExport
    countOnScan
    hasAutomaticOrdering
    automaticOrderDays {
      day
      time
    }
    automaticOrderInterval
    ignoredProducts
    hasLechler
    hasSpiesHecker
    hasStandox
    hasPercotop
    isActive
    avoidHolidays
  }
}
    `;
export type UpdateAccountMutationFn = Apollo.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useUpdateAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, options);
      }
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<UpdateAccountMutation, UpdateAccountMutationVariables>;
export const UpdateAccountSettingsDocument = gql`
    mutation UpdateAccountSettings($args: UpdateAccountSettingsInput!) {
  updateAccountSettings(args: $args) {
    id
    accountName
    usersCanEdit
    usersCanExport
    countOnScan
    hasAutomaticOrdering
    automaticOrderInterval
    ignoredProducts
    hasLechler
    hasSpiesHecker
    hasStandox
    hasPercotop
    isActive
    avoidHolidays
    automaticOrderDays {
      day
      time
    }
  }
}
    `;
export type UpdateAccountSettingsMutationFn = Apollo.MutationFunction<UpdateAccountSettingsMutation, UpdateAccountSettingsMutationVariables>;

/**
 * __useUpdateAccountSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateAccountSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountSettingsMutation, { data, loading, error }] = useUpdateAccountSettingsMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useUpdateAccountSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountSettingsMutation, UpdateAccountSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountSettingsMutation, UpdateAccountSettingsMutationVariables>(UpdateAccountSettingsDocument, options);
      }
export type UpdateAccountSettingsMutationHookResult = ReturnType<typeof useUpdateAccountSettingsMutation>;
export type UpdateAccountSettingsMutationResult = Apollo.MutationResult<UpdateAccountSettingsMutation>;
export type UpdateAccountSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateAccountSettingsMutation, UpdateAccountSettingsMutationVariables>;
export const UpdateCartItemDocument = gql`
    mutation UpdateCartItem($item: CartItemInput!, $cartId: String!) {
  updateCartItem(item: $item, cartId: $cartId) {
    id
    orderLines {
      amount
      productNumber
      manuallyAdded
      name
      description
      images {
        url
        name
        sorting
      }
    }
    automaticOrderDate
    wasDateChanged
    comment
  }
}
    `;
export type UpdateCartItemMutationFn = Apollo.MutationFunction<UpdateCartItemMutation, UpdateCartItemMutationVariables>;

/**
 * __useUpdateCartItemMutation__
 *
 * To run a mutation, you first call `useUpdateCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartItemMutation, { data, loading, error }] = useUpdateCartItemMutation({
 *   variables: {
 *      item: // value for 'item'
 *      cartId: // value for 'cartId'
 *   },
 * });
 */
export function useUpdateCartItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCartItemMutation, UpdateCartItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCartItemMutation, UpdateCartItemMutationVariables>(UpdateCartItemDocument, options);
      }
export type UpdateCartItemMutationHookResult = ReturnType<typeof useUpdateCartItemMutation>;
export type UpdateCartItemMutationResult = Apollo.MutationResult<UpdateCartItemMutation>;
export type UpdateCartItemMutationOptions = Apollo.BaseMutationOptions<UpdateCartItemMutation, UpdateCartItemMutationVariables>;
export const UpdateCustomItemDocument = gql`
    mutation UpdateCustomItem($updateCustomItemId: String!, $name: String, $description: String, $barcode: String, $price: Int, $category: String, $units: String, $inStock: Int, $supplier: String) {
  updateCustomItem(
    id: $updateCustomItemId
    name: $name
    description: $description
    barcode: $barcode
    price: $price
    category: $category
    units: $units
    inStock: $inStock
    supplier: $supplier
  ) {
    id
    productNumber
    accountId
    category
    minimum
    maximum
    amount
    automaticOrderingState
    name
    description
    status
    barcode
    images {
      url
      name
      sorting
    }
    alreadyOrdered
    inCart
    isCustom
    unit
    amountPerUnit
    supplier
    price
  }
}
    `;
export type UpdateCustomItemMutationFn = Apollo.MutationFunction<UpdateCustomItemMutation, UpdateCustomItemMutationVariables>;

/**
 * __useUpdateCustomItemMutation__
 *
 * To run a mutation, you first call `useUpdateCustomItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomItemMutation, { data, loading, error }] = useUpdateCustomItemMutation({
 *   variables: {
 *      updateCustomItemId: // value for 'updateCustomItemId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      barcode: // value for 'barcode'
 *      price: // value for 'price'
 *      category: // value for 'category'
 *      units: // value for 'units'
 *      inStock: // value for 'inStock'
 *      supplier: // value for 'supplier'
 *   },
 * });
 */
export function useUpdateCustomItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomItemMutation, UpdateCustomItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomItemMutation, UpdateCustomItemMutationVariables>(UpdateCustomItemDocument, options);
      }
export type UpdateCustomItemMutationHookResult = ReturnType<typeof useUpdateCustomItemMutation>;
export type UpdateCustomItemMutationResult = Apollo.MutationResult<UpdateCustomItemMutation>;
export type UpdateCustomItemMutationOptions = Apollo.BaseMutationOptions<UpdateCustomItemMutation, UpdateCustomItemMutationVariables>;
export const UpdateHolidayDocument = gql`
    mutation UpdateHoliday($updateHolidayId: String!, $day: String, $holidayName: String) {
  updateHoliday(id: $updateHolidayId, day: $day, holiday_name: $holidayName) {
    id
    day
    holidayName
  }
}
    `;
export type UpdateHolidayMutationFn = Apollo.MutationFunction<UpdateHolidayMutation, UpdateHolidayMutationVariables>;

/**
 * __useUpdateHolidayMutation__
 *
 * To run a mutation, you first call `useUpdateHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHolidayMutation, { data, loading, error }] = useUpdateHolidayMutation({
 *   variables: {
 *      updateHolidayId: // value for 'updateHolidayId'
 *      day: // value for 'day'
 *      holidayName: // value for 'holidayName'
 *   },
 * });
 */
export function useUpdateHolidayMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHolidayMutation, UpdateHolidayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHolidayMutation, UpdateHolidayMutationVariables>(UpdateHolidayDocument, options);
      }
export type UpdateHolidayMutationHookResult = ReturnType<typeof useUpdateHolidayMutation>;
export type UpdateHolidayMutationResult = Apollo.MutationResult<UpdateHolidayMutation>;
export type UpdateHolidayMutationOptions = Apollo.BaseMutationOptions<UpdateHolidayMutation, UpdateHolidayMutationVariables>;
export const UpdateInventoryItemDocument = gql`
    mutation UpdateInventoryItem($productNumber: String!, $inStock: Int, $minimum: Int, $maximum: Int, $automaticOrdering: AutomaticOrdering, $action: String) {
  updateInventoryItem(
    productNumber: $productNumber
    inStock: $inStock
    minimum: $minimum
    maximum: $maximum
    automaticOrdering: $automaticOrdering
    action: $action
  ) {
    id
    productNumber
    accountId
    category
    minimum
    maximum
    amount
    automaticOrderingState
    name
    description
    status
    barcode
    images {
      url
      name
      sorting
    }
    alreadyOrdered
    inCart
    isCustom
    unit
    amountPerUnit
    supplier
    price
  }
}
    `;
export type UpdateInventoryItemMutationFn = Apollo.MutationFunction<UpdateInventoryItemMutation, UpdateInventoryItemMutationVariables>;

/**
 * __useUpdateInventoryItemMutation__
 *
 * To run a mutation, you first call `useUpdateInventoryItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInventoryItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInventoryItemMutation, { data, loading, error }] = useUpdateInventoryItemMutation({
 *   variables: {
 *      productNumber: // value for 'productNumber'
 *      inStock: // value for 'inStock'
 *      minimum: // value for 'minimum'
 *      maximum: // value for 'maximum'
 *      automaticOrdering: // value for 'automaticOrdering'
 *      action: // value for 'action'
 *   },
 * });
 */
export function useUpdateInventoryItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInventoryItemMutation, UpdateInventoryItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInventoryItemMutation, UpdateInventoryItemMutationVariables>(UpdateInventoryItemDocument, options);
      }
export type UpdateInventoryItemMutationHookResult = ReturnType<typeof useUpdateInventoryItemMutation>;
export type UpdateInventoryItemMutationResult = Apollo.MutationResult<UpdateInventoryItemMutation>;
export type UpdateInventoryItemMutationOptions = Apollo.BaseMutationOptions<UpdateInventoryItemMutation, UpdateInventoryItemMutationVariables>;
export const UpdateOrderItemsDocument = gql`
    mutation UpdateOrderItems($items: [OrderItemInput!]!, $orderId: String!, $newItems: [OrderNewItemInput!]!, $withWarningMessage: String) {
  updateOrderItems(
    items: $items
    orderId: $orderId
    newItems: $newItems
    withWarningMessage: $withWarningMessage
  ) {
    id
    accountId
    orderLines {
      id
      name
      description
      price
      taken
      amount
      productNumber
      excluded
      barcode
      images {
        url
        name
        sorting
      }
      status
      sorting
    }
    acknowledged
    packed
    picked
    completed
    orderedFrom
    orderDate
    comments
  }
}
    `;
export type UpdateOrderItemsMutationFn = Apollo.MutationFunction<UpdateOrderItemsMutation, UpdateOrderItemsMutationVariables>;

/**
 * __useUpdateOrderItemsMutation__
 *
 * To run a mutation, you first call `useUpdateOrderItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderItemsMutation, { data, loading, error }] = useUpdateOrderItemsMutation({
 *   variables: {
 *      items: // value for 'items'
 *      orderId: // value for 'orderId'
 *      newItems: // value for 'newItems'
 *      withWarningMessage: // value for 'withWarningMessage'
 *   },
 * });
 */
export function useUpdateOrderItemsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderItemsMutation, UpdateOrderItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderItemsMutation, UpdateOrderItemsMutationVariables>(UpdateOrderItemsDocument, options);
      }
export type UpdateOrderItemsMutationHookResult = ReturnType<typeof useUpdateOrderItemsMutation>;
export type UpdateOrderItemsMutationResult = Apollo.MutationResult<UpdateOrderItemsMutation>;
export type UpdateOrderItemsMutationOptions = Apollo.BaseMutationOptions<UpdateOrderItemsMutation, UpdateOrderItemsMutationVariables>;
export const UpdateScannedItemDocument = gql`
    mutation UpdateScannedItem($updateScannedItemId: String!, $prodNo: String!, $newAmount: Int!) {
  updateScannedItem(
    id: $updateScannedItemId
    prodNo: $prodNo
    newAmount: $newAmount
  ) {
    id
    accountId
    items {
      id
      amount
      barcode
      eventIds
      eventTime
      productIds
    }
  }
}
    `;
export type UpdateScannedItemMutationFn = Apollo.MutationFunction<UpdateScannedItemMutation, UpdateScannedItemMutationVariables>;

/**
 * __useUpdateScannedItemMutation__
 *
 * To run a mutation, you first call `useUpdateScannedItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScannedItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScannedItemMutation, { data, loading, error }] = useUpdateScannedItemMutation({
 *   variables: {
 *      updateScannedItemId: // value for 'updateScannedItemId'
 *      prodNo: // value for 'prodNo'
 *      newAmount: // value for 'newAmount'
 *   },
 * });
 */
export function useUpdateScannedItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScannedItemMutation, UpdateScannedItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScannedItemMutation, UpdateScannedItemMutationVariables>(UpdateScannedItemDocument, options);
      }
export type UpdateScannedItemMutationHookResult = ReturnType<typeof useUpdateScannedItemMutation>;
export type UpdateScannedItemMutationResult = Apollo.MutationResult<UpdateScannedItemMutation>;
export type UpdateScannedItemMutationOptions = Apollo.BaseMutationOptions<UpdateScannedItemMutation, UpdateScannedItemMutationVariables>;
export const UpdateWatchedProductStatusDocument = gql`
    mutation UpdateWatchedProductStatus($updateWatchedProductStatusId: String, $response: String) {
  updateWatchedProductStatus(
    id: $updateWatchedProductStatusId
    response: $response
  ) {
    id
    customerId
    productNumber
    timesOrdered
    totalOrdered
    recommendedMin
    recommendedMax
    actionType
    createdAt
    updatedAt
  }
}
    `;
export type UpdateWatchedProductStatusMutationFn = Apollo.MutationFunction<UpdateWatchedProductStatusMutation, UpdateWatchedProductStatusMutationVariables>;

/**
 * __useUpdateWatchedProductStatusMutation__
 *
 * To run a mutation, you first call `useUpdateWatchedProductStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWatchedProductStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWatchedProductStatusMutation, { data, loading, error }] = useUpdateWatchedProductStatusMutation({
 *   variables: {
 *      updateWatchedProductStatusId: // value for 'updateWatchedProductStatusId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useUpdateWatchedProductStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWatchedProductStatusMutation, UpdateWatchedProductStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWatchedProductStatusMutation, UpdateWatchedProductStatusMutationVariables>(UpdateWatchedProductStatusDocument, options);
      }
export type UpdateWatchedProductStatusMutationHookResult = ReturnType<typeof useUpdateWatchedProductStatusMutation>;
export type UpdateWatchedProductStatusMutationResult = Apollo.MutationResult<UpdateWatchedProductStatusMutation>;
export type UpdateWatchedProductStatusMutationOptions = Apollo.BaseMutationOptions<UpdateWatchedProductStatusMutation, UpdateWatchedProductStatusMutationVariables>;
export const AccountLogsDocument = gql`
    query AccountLogs($limit: Int, $offset: Int) {
  accountLogs(limit: $limit, offset: $offset) {
    id
    accountId
    message
    operationType
    operationGroup
    extraData
    createdAt
  }
}
    `;

/**
 * __useAccountLogsQuery__
 *
 * To run a query within a React component, call `useAccountLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountLogsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAccountLogsQuery(baseOptions?: Apollo.QueryHookOptions<AccountLogsQuery, AccountLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountLogsQuery, AccountLogsQueryVariables>(AccountLogsDocument, options);
      }
export function useAccountLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountLogsQuery, AccountLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountLogsQuery, AccountLogsQueryVariables>(AccountLogsDocument, options);
        }
export type AccountLogsQueryHookResult = ReturnType<typeof useAccountLogsQuery>;
export type AccountLogsLazyQueryHookResult = ReturnType<typeof useAccountLogsLazyQuery>;
export type AccountLogsQueryResult = Apollo.QueryResult<AccountLogsQuery, AccountLogsQueryVariables>;
export const AccountSettingsDocument = gql`
    query AccountSettings($accountSettingsId: String) {
  accountSettings(id: $accountSettingsId) {
    accountancyYear
    id
    accountName
    cvr
    usersCanEdit
    usersCanExport
    countOnScan
    hasAutomaticOrdering
    automaticOrderDays {
      day
      time
    }
    automaticOrderInterval
    ignoredProducts
    hasLechler
    hasSpiesHecker
    hasStandox
    hasPercotop
    isActive
    avoidHolidays
  }
}
    `;

/**
 * __useAccountSettingsQuery__
 *
 * To run a query within a React component, call `useAccountSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountSettingsQuery({
 *   variables: {
 *      accountSettingsId: // value for 'accountSettingsId'
 *   },
 * });
 */
export function useAccountSettingsQuery(baseOptions?: Apollo.QueryHookOptions<AccountSettingsQuery, AccountSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountSettingsQuery, AccountSettingsQueryVariables>(AccountSettingsDocument, options);
      }
export function useAccountSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountSettingsQuery, AccountSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountSettingsQuery, AccountSettingsQueryVariables>(AccountSettingsDocument, options);
        }
export type AccountSettingsQueryHookResult = ReturnType<typeof useAccountSettingsQuery>;
export type AccountSettingsLazyQueryHookResult = ReturnType<typeof useAccountSettingsLazyQuery>;
export type AccountSettingsQueryResult = Apollo.QueryResult<AccountSettingsQuery, AccountSettingsQueryVariables>;
export const AccountsDocument = gql`
    query Accounts {
  accounts {
    id
    accountName
    usersCanEdit
    usersCanExport
    countOnScan
    hasAutomaticOrdering
    automaticOrderDays {
      day
      time
    }
    automaticOrderInterval
    ignoredProducts
    hasLechler
    hasSpiesHecker
    hasStandox
    hasPercotop
    isActive
    avoidHolidays
  }
}
    `;

/**
 * __useAccountsQuery__
 *
 * To run a query within a React component, call `useAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountsQuery(baseOptions?: Apollo.QueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
      }
export function useAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
        }
export type AccountsQueryHookResult = ReturnType<typeof useAccountsQuery>;
export type AccountsLazyQueryHookResult = ReturnType<typeof useAccountsLazyQuery>;
export type AccountsQueryResult = Apollo.QueryResult<AccountsQuery, AccountsQueryVariables>;
export const ActiveOrdersDocument = gql`
    query ActiveOrders {
  activeOrders {
    id
    accountId
    orderLines {
      id
      name
      description
      price
      taken
      amount
      productNumber
      excluded
      barcode
      images {
        name
        sorting
        url
      }
      status
      sorting
      amountPerUnit
    }
    acknowledged
    packed
    picked
    completed
    orderedFrom
    orderDate
    comments
    backorder
    invoiced
    deliveryId
    cancelled
  }
}
    `;

/**
 * __useActiveOrdersQuery__
 *
 * To run a query within a React component, call `useActiveOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveOrdersQuery(baseOptions?: Apollo.QueryHookOptions<ActiveOrdersQuery, ActiveOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveOrdersQuery, ActiveOrdersQueryVariables>(ActiveOrdersDocument, options);
      }
export function useActiveOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveOrdersQuery, ActiveOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveOrdersQuery, ActiveOrdersQueryVariables>(ActiveOrdersDocument, options);
        }
export type ActiveOrdersQueryHookResult = ReturnType<typeof useActiveOrdersQuery>;
export type ActiveOrdersLazyQueryHookResult = ReturnType<typeof useActiveOrdersLazyQuery>;
export type ActiveOrdersQueryResult = Apollo.QueryResult<ActiveOrdersQuery, ActiveOrdersQueryVariables>;
export const CartsDocument = gql`
    query Carts {
  carts {
    id
    orderLines {
      amount
      description
      images {
        name
        sorting
        url
      }
      manuallyAdded
      name
      productNumber
    }
    automaticOrderDate
    wasDateChanged
    comment
  }
}
    `;

/**
 * __useCartsQuery__
 *
 * To run a query within a React component, call `useCartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCartsQuery(baseOptions?: Apollo.QueryHookOptions<CartsQuery, CartsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CartsQuery, CartsQueryVariables>(CartsDocument, options);
      }
export function useCartsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CartsQuery, CartsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CartsQuery, CartsQueryVariables>(CartsDocument, options);
        }
export type CartsQueryHookResult = ReturnType<typeof useCartsQuery>;
export type CartsLazyQueryHookResult = ReturnType<typeof useCartsLazyQuery>;
export type CartsQueryResult = Apollo.QueryResult<CartsQuery, CartsQueryVariables>;
export const QueryDocument = gql`
    query Query {
  frequentManuallyOrderedItems
}
    `;

/**
 * __useQueryQuery__
 *
 * To run a query within a React component, call `useQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useQueryQuery(baseOptions?: Apollo.QueryHookOptions<QueryQuery, QueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryQuery, QueryQueryVariables>(QueryDocument, options);
      }
export function useQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryQuery, QueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryQuery, QueryQueryVariables>(QueryDocument, options);
        }
export type QueryQueryHookResult = ReturnType<typeof useQueryQuery>;
export type QueryLazyQueryHookResult = ReturnType<typeof useQueryLazyQuery>;
export type QueryQueryResult = Apollo.QueryResult<QueryQuery, QueryQueryVariables>;
export const HolidayDocument = gql`
    query Holiday($holidayId: String!) {
  holiday(id: $holidayId) {
    id
    day
    holidayName
  }
}
    `;

/**
 * __useHolidayQuery__
 *
 * To run a query within a React component, call `useHolidayQuery` and pass it any options that fit your needs.
 * When your component renders, `useHolidayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHolidayQuery({
 *   variables: {
 *      holidayId: // value for 'holidayId'
 *   },
 * });
 */
export function useHolidayQuery(baseOptions: Apollo.QueryHookOptions<HolidayQuery, HolidayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HolidayQuery, HolidayQueryVariables>(HolidayDocument, options);
      }
export function useHolidayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HolidayQuery, HolidayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HolidayQuery, HolidayQueryVariables>(HolidayDocument, options);
        }
export type HolidayQueryHookResult = ReturnType<typeof useHolidayQuery>;
export type HolidayLazyQueryHookResult = ReturnType<typeof useHolidayLazyQuery>;
export type HolidayQueryResult = Apollo.QueryResult<HolidayQuery, HolidayQueryVariables>;
export const HolidaysDocument = gql`
    query Holidays {
  holidays {
    id
    day
    holidayName
  }
}
    `;

/**
 * __useHolidaysQuery__
 *
 * To run a query within a React component, call `useHolidaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useHolidaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHolidaysQuery({
 *   variables: {
 *   },
 * });
 */
export function useHolidaysQuery(baseOptions?: Apollo.QueryHookOptions<HolidaysQuery, HolidaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HolidaysQuery, HolidaysQueryVariables>(HolidaysDocument, options);
      }
export function useHolidaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HolidaysQuery, HolidaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HolidaysQuery, HolidaysQueryVariables>(HolidaysDocument, options);
        }
export type HolidaysQueryHookResult = ReturnType<typeof useHolidaysQuery>;
export type HolidaysLazyQueryHookResult = ReturnType<typeof useHolidaysLazyQuery>;
export type HolidaysQueryResult = Apollo.QueryResult<HolidaysQuery, HolidaysQueryVariables>;
export const InventoryValueDocument = gql`
    query InventoryValue($start: String, $end: String) {
  inventoryValue(start: $start, end: $end) {
    currentValue
    changeDirection
    changePercent
  }
}
    `;

/**
 * __useInventoryValueQuery__
 *
 * To run a query within a React component, call `useInventoryValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryValueQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useInventoryValueQuery(baseOptions?: Apollo.QueryHookOptions<InventoryValueQuery, InventoryValueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InventoryValueQuery, InventoryValueQueryVariables>(InventoryValueDocument, options);
      }
export function useInventoryValueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InventoryValueQuery, InventoryValueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InventoryValueQuery, InventoryValueQueryVariables>(InventoryValueDocument, options);
        }
export type InventoryValueQueryHookResult = ReturnType<typeof useInventoryValueQuery>;
export type InventoryValueLazyQueryHookResult = ReturnType<typeof useInventoryValueLazyQuery>;
export type InventoryValueQueryResult = Apollo.QueryResult<InventoryValueQuery, InventoryValueQueryVariables>;
export const InventoryDocument = gql`
    query Inventory {
  inventory {
    id
    productNumber
    accountId
    category
    minimum
    maximum
    amount
    automaticOrderingState
    name
    description
    status
    barcode
    images {
      url
      name
      sorting
    }
    alreadyOrdered
    inCart
    isCustom
    unit
    amountPerUnit
    supplier
    price
  }
}
    `;

/**
 * __useInventoryQuery__
 *
 * To run a query within a React component, call `useInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useInventoryQuery(baseOptions?: Apollo.QueryHookOptions<InventoryQuery, InventoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InventoryQuery, InventoryQueryVariables>(InventoryDocument, options);
      }
export function useInventoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InventoryQuery, InventoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InventoryQuery, InventoryQueryVariables>(InventoryDocument, options);
        }
export type InventoryQueryHookResult = ReturnType<typeof useInventoryQuery>;
export type InventoryLazyQueryHookResult = ReturnType<typeof useInventoryLazyQuery>;
export type InventoryQueryResult = Apollo.QueryResult<InventoryQuery, InventoryQueryVariables>;
export const InventoryItemDocument = gql`
    query InventoryItem($productNumber: String!) {
  inventoryItem(productNumber: $productNumber) {
    id
    accountId
    alreadyOrdered
    amount
    amountPerUnit
    automaticOrderingState
    barcode
    category
    description
    inCart
    isCustom
    maximum
    minimum
    name
    price
    productNumber
    status
    supplier
    unit
    images {
      name
      sorting
      url
    }
  }
}
    `;

/**
 * __useInventoryItemQuery__
 *
 * To run a query within a React component, call `useInventoryItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryItemQuery({
 *   variables: {
 *      productNumber: // value for 'productNumber'
 *   },
 * });
 */
export function useInventoryItemQuery(baseOptions: Apollo.QueryHookOptions<InventoryItemQuery, InventoryItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InventoryItemQuery, InventoryItemQueryVariables>(InventoryItemDocument, options);
      }
export function useInventoryItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InventoryItemQuery, InventoryItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InventoryItemQuery, InventoryItemQueryVariables>(InventoryItemDocument, options);
        }
export type InventoryItemQueryHookResult = ReturnType<typeof useInventoryItemQuery>;
export type InventoryItemLazyQueryHookResult = ReturnType<typeof useInventoryItemLazyQuery>;
export type InventoryItemQueryResult = Apollo.QueryResult<InventoryItemQuery, InventoryItemQueryVariables>;
export const InventoryIntakeDocument = gql`
    query InventoryIntake($month: Int) {
  inventoryIntake(month: $month) {
    currentValue
    changeDirection
    changePercent
  }
}
    `;

/**
 * __useInventoryIntakeQuery__
 *
 * To run a query within a React component, call `useInventoryIntakeQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryIntakeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryIntakeQuery({
 *   variables: {
 *      month: // value for 'month'
 *   },
 * });
 */
export function useInventoryIntakeQuery(baseOptions?: Apollo.QueryHookOptions<InventoryIntakeQuery, InventoryIntakeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InventoryIntakeQuery, InventoryIntakeQueryVariables>(InventoryIntakeDocument, options);
      }
export function useInventoryIntakeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InventoryIntakeQuery, InventoryIntakeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InventoryIntakeQuery, InventoryIntakeQueryVariables>(InventoryIntakeDocument, options);
        }
export type InventoryIntakeQueryHookResult = ReturnType<typeof useInventoryIntakeQuery>;
export type InventoryIntakeLazyQueryHookResult = ReturnType<typeof useInventoryIntakeLazyQuery>;
export type InventoryIntakeQueryResult = Apollo.QueryResult<InventoryIntakeQuery, InventoryIntakeQueryVariables>;
export const InventoryItemHistoryDocument = gql`
    query InventoryItemHistory($productNumber: String!, $limit: Int, $offset: Int) {
  inventoryItemHistory(
    productNumber: $productNumber
    limit: $limit
    offset: $offset
  ) {
    id
    productIds
    eventTime
    eventType
    eventAction
    productNumber
    accountId
    eventReverted
    revertTime
    fromOrderNumber
  }
}
    `;

/**
 * __useInventoryItemHistoryQuery__
 *
 * To run a query within a React component, call `useInventoryItemHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryItemHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryItemHistoryQuery({
 *   variables: {
 *      productNumber: // value for 'productNumber'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useInventoryItemHistoryQuery(baseOptions: Apollo.QueryHookOptions<InventoryItemHistoryQuery, InventoryItemHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InventoryItemHistoryQuery, InventoryItemHistoryQueryVariables>(InventoryItemHistoryDocument, options);
      }
export function useInventoryItemHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InventoryItemHistoryQuery, InventoryItemHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InventoryItemHistoryQuery, InventoryItemHistoryQueryVariables>(InventoryItemHistoryDocument, options);
        }
export type InventoryItemHistoryQueryHookResult = ReturnType<typeof useInventoryItemHistoryQuery>;
export type InventoryItemHistoryLazyQueryHookResult = ReturnType<typeof useInventoryItemHistoryLazyQuery>;
export type InventoryItemHistoryQueryResult = Apollo.QueryResult<InventoryItemHistoryQuery, InventoryItemHistoryQueryVariables>;
export const InventoryOutgoingDocument = gql`
    query InventoryOutgoing($month: Int) {
  inventoryOutgoing(month: $month) {
    currentValue
    changeDirection
    changePercent
  }
}
    `;

/**
 * __useInventoryOutgoingQuery__
 *
 * To run a query within a React component, call `useInventoryOutgoingQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryOutgoingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryOutgoingQuery({
 *   variables: {
 *      month: // value for 'month'
 *   },
 * });
 */
export function useInventoryOutgoingQuery(baseOptions?: Apollo.QueryHookOptions<InventoryOutgoingQuery, InventoryOutgoingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InventoryOutgoingQuery, InventoryOutgoingQueryVariables>(InventoryOutgoingDocument, options);
      }
export function useInventoryOutgoingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InventoryOutgoingQuery, InventoryOutgoingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InventoryOutgoingQuery, InventoryOutgoingQueryVariables>(InventoryOutgoingDocument, options);
        }
export type InventoryOutgoingQueryHookResult = ReturnType<typeof useInventoryOutgoingQuery>;
export type InventoryOutgoingLazyQueryHookResult = ReturnType<typeof useInventoryOutgoingLazyQuery>;
export type InventoryOutgoingQueryResult = Apollo.QueryResult<InventoryOutgoingQuery, InventoryOutgoingQueryVariables>;
export const InventoryValueOnDateDocument = gql`
    query InventoryValueOnDate($date: String!) {
  inventoryValueOnDate(date: $date) {
    id
    productNumber
    accountId
    category
    minimum
    maximum
    amount
    automaticOrderingState
    name
    description
    status
    barcode
    images {
      url
      name
      sorting
    }
    alreadyOrdered
    inCart
    isCustom
    unit
    amountPerUnit
    supplier
    price
  }
}
    `;

/**
 * __useInventoryValueOnDateQuery__
 *
 * To run a query within a React component, call `useInventoryValueOnDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryValueOnDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryValueOnDateQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useInventoryValueOnDateQuery(baseOptions: Apollo.QueryHookOptions<InventoryValueOnDateQuery, InventoryValueOnDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InventoryValueOnDateQuery, InventoryValueOnDateQueryVariables>(InventoryValueOnDateDocument, options);
      }
export function useInventoryValueOnDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InventoryValueOnDateQuery, InventoryValueOnDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InventoryValueOnDateQuery, InventoryValueOnDateQueryVariables>(InventoryValueOnDateDocument, options);
        }
export type InventoryValueOnDateQueryHookResult = ReturnType<typeof useInventoryValueOnDateQuery>;
export type InventoryValueOnDateLazyQueryHookResult = ReturnType<typeof useInventoryValueOnDateLazyQuery>;
export type InventoryValueOnDateQueryResult = Apollo.QueryResult<InventoryValueOnDateQuery, InventoryValueOnDateQueryVariables>;
export const ItemListDataDocument = gql`
    query ItemListData {
  itemListData
}
    `;

/**
 * __useItemListDataQuery__
 *
 * To run a query within a React component, call `useItemListDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemListDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemListDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useItemListDataQuery(baseOptions?: Apollo.QueryHookOptions<ItemListDataQuery, ItemListDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ItemListDataQuery, ItemListDataQueryVariables>(ItemListDataDocument, options);
      }
export function useItemListDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemListDataQuery, ItemListDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ItemListDataQuery, ItemListDataQueryVariables>(ItemListDataDocument, options);
        }
export type ItemListDataQueryHookResult = ReturnType<typeof useItemListDataQuery>;
export type ItemListDataLazyQueryHookResult = ReturnType<typeof useItemListDataLazyQuery>;
export type ItemListDataQueryResult = Apollo.QueryResult<ItemListDataQuery, ItemListDataQueryVariables>;
export const MaterialValuesDocument = gql`
    query MaterialValues($period: String, $accountancyYear: Int) {
  materialValues(period: $period, accountancyYear: $accountancyYear) {
    id
    value
    percentChange
    prodNo
    productData
    category
  }
}
    `;

/**
 * __useMaterialValuesQuery__
 *
 * To run a query within a React component, call `useMaterialValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaterialValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaterialValuesQuery({
 *   variables: {
 *      period: // value for 'period'
 *      accountancyYear: // value for 'accountancyYear'
 *   },
 * });
 */
export function useMaterialValuesQuery(baseOptions?: Apollo.QueryHookOptions<MaterialValuesQuery, MaterialValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MaterialValuesQuery, MaterialValuesQueryVariables>(MaterialValuesDocument, options);
      }
export function useMaterialValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MaterialValuesQuery, MaterialValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MaterialValuesQuery, MaterialValuesQueryVariables>(MaterialValuesDocument, options);
        }
export type MaterialValuesQueryHookResult = ReturnType<typeof useMaterialValuesQuery>;
export type MaterialValuesLazyQueryHookResult = ReturnType<typeof useMaterialValuesLazyQuery>;
export type MaterialValuesQueryResult = Apollo.QueryResult<MaterialValuesQuery, MaterialValuesQueryVariables>;
export const OrderHistoryDocument = gql`
    query OrderHistory($month: Int!, $year: Int!) {
  orderHistory(month: $month, year: $year) {
    id
    orderId
    automaticOrderDate
    orderDate
  }
}
    `;

/**
 * __useOrderHistoryQuery__
 *
 * To run a query within a React component, call `useOrderHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderHistoryQuery({
 *   variables: {
 *      month: // value for 'month'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useOrderHistoryQuery(baseOptions: Apollo.QueryHookOptions<OrderHistoryQuery, OrderHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderHistoryQuery, OrderHistoryQueryVariables>(OrderHistoryDocument, options);
      }
export function useOrderHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderHistoryQuery, OrderHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderHistoryQuery, OrderHistoryQueryVariables>(OrderHistoryDocument, options);
        }
export type OrderHistoryQueryHookResult = ReturnType<typeof useOrderHistoryQuery>;
export type OrderHistoryLazyQueryHookResult = ReturnType<typeof useOrderHistoryLazyQuery>;
export type OrderHistoryQueryResult = Apollo.QueryResult<OrderHistoryQuery, OrderHistoryQueryVariables>;
export const OrdersDocument = gql`
    query Orders {
  orders {
    id
    accountId
    orderLines {
      id
      name
      description
      price
      taken
      amount
      productNumber
      excluded
      barcode
      images {
        name
        sorting
        url
      }
      status
      sorting
      amountPerUnit
    }
    acknowledged
    packed
    picked
    completed
    orderedFrom
    orderDate
    comments
    backorder
    invoiced
    deliveryId
    cancelled
  }
}
    `;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrdersQuery(baseOptions?: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const ProductDocument = gql`
    query Product($barcode: String, $productNumber: String) {
  product(barcode: $barcode, productNumber: $productNumber) {
    id
    prodNo
    productData
    customData
    updated_by
    created_at
    updated_at
    last_sync
  }
}
    `;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      barcode: // value for 'barcode'
 *      productNumber: // value for 'productNumber'
 *   },
 * });
 */
export function useProductQuery(baseOptions?: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
      }
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
        }
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
export const ProductsDocument = gql`
    query Products($searchTerm: String, $productNumbers: [String]) {
  products(searchTerm: $searchTerm, productNumbers: $productNumbers) {
    id
    prodNo
    productData
    customData
    updated_by
    created_at
    updated_at
    last_sync
  }
}
    `;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      productNumbers: // value for 'productNumbers'
 *   },
 * });
 */
export function useProductsQuery(baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const ScansDocument = gql`
    query Scans {
  scans {
    id
    accountId
    items {
      id
      amount
      barcode
      eventIds
      eventTime
      productIds
    }
  }
}
    `;

/**
 * __useScansQuery__
 *
 * To run a query within a React component, call `useScansQuery` and pass it any options that fit your needs.
 * When your component renders, `useScansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScansQuery({
 *   variables: {
 *   },
 * });
 */
export function useScansQuery(baseOptions?: Apollo.QueryHookOptions<ScansQuery, ScansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScansQuery, ScansQueryVariables>(ScansDocument, options);
      }
export function useScansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScansQuery, ScansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScansQuery, ScansQueryVariables>(ScansDocument, options);
        }
export type ScansQueryHookResult = ReturnType<typeof useScansQuery>;
export type ScansLazyQueryHookResult = ReturnType<typeof useScansLazyQuery>;
export type ScansQueryResult = Apollo.QueryResult<ScansQuery, ScansQueryVariables>;
export const TotalsValueDocument = gql`
    query TotalsValue($period: String, $accountancyYear: Int) {
  totalsValue(period: $period, accountancyYear: $accountancyYear) {
    id
    value
    name
    percentChange
    prodNo
    productData
    category
  }
}
    `;

/**
 * __useTotalsValueQuery__
 *
 * To run a query within a React component, call `useTotalsValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalsValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalsValueQuery({
 *   variables: {
 *      period: // value for 'period'
 *      accountancyYear: // value for 'accountancyYear'
 *   },
 * });
 */
export function useTotalsValueQuery(baseOptions?: Apollo.QueryHookOptions<TotalsValueQuery, TotalsValueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TotalsValueQuery, TotalsValueQueryVariables>(TotalsValueDocument, options);
      }
export function useTotalsValueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TotalsValueQuery, TotalsValueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TotalsValueQuery, TotalsValueQueryVariables>(TotalsValueDocument, options);
        }
export type TotalsValueQueryHookResult = ReturnType<typeof useTotalsValueQuery>;
export type TotalsValueLazyQueryHookResult = ReturnType<typeof useTotalsValueLazyQuery>;
export type TotalsValueQueryResult = Apollo.QueryResult<TotalsValueQuery, TotalsValueQueryVariables>;
export const UserDocument = gql`
    query User {
  user {
    id
    email
    accountId
    role
    name
    lang
    ssoAccountId
    ssoDistributorId
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const ValueChartDataDocument = gql`
    query ValueChartData($year: Int, $startMonth: Int) {
  valueChartData(year: $year, startMonth: $startMonth) {
    name
    inventoryValue
    intakeValue
    outgoingValue
    year
  }
}
    `;

/**
 * __useValueChartDataQuery__
 *
 * To run a query within a React component, call `useValueChartDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useValueChartDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValueChartDataQuery({
 *   variables: {
 *      year: // value for 'year'
 *      startMonth: // value for 'startMonth'
 *   },
 * });
 */
export function useValueChartDataQuery(baseOptions?: Apollo.QueryHookOptions<ValueChartDataQuery, ValueChartDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValueChartDataQuery, ValueChartDataQueryVariables>(ValueChartDataDocument, options);
      }
export function useValueChartDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValueChartDataQuery, ValueChartDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValueChartDataQuery, ValueChartDataQueryVariables>(ValueChartDataDocument, options);
        }
export type ValueChartDataQueryHookResult = ReturnType<typeof useValueChartDataQuery>;
export type ValueChartDataLazyQueryHookResult = ReturnType<typeof useValueChartDataLazyQuery>;
export type ValueChartDataQueryResult = Apollo.QueryResult<ValueChartDataQuery, ValueChartDataQueryVariables>;
export const WatchedProductsDocument = gql`
    query WatchedProducts {
  watchedProducts {
    id
    customerId
    productNumber
    timesOrdered
    totalOrdered
    recommendedMin
    recommendedMax
    actionType
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useWatchedProductsQuery__
 *
 * To run a query within a React component, call `useWatchedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWatchedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchedProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWatchedProductsQuery(baseOptions?: Apollo.QueryHookOptions<WatchedProductsQuery, WatchedProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WatchedProductsQuery, WatchedProductsQueryVariables>(WatchedProductsDocument, options);
      }
export function useWatchedProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WatchedProductsQuery, WatchedProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WatchedProductsQuery, WatchedProductsQueryVariables>(WatchedProductsDocument, options);
        }
export type WatchedProductsQueryHookResult = ReturnType<typeof useWatchedProductsQuery>;
export type WatchedProductsLazyQueryHookResult = ReturnType<typeof useWatchedProductsLazyQuery>;
export type WatchedProductsQueryResult = Apollo.QueryResult<WatchedProductsQuery, WatchedProductsQueryVariables>;
export const KeepAliveDocument = gql`
    subscription KeepAlive {
  keepAlivePing
}
    `;

/**
 * __useKeepAliveSubscription__
 *
 * To run a query within a React component, call `useKeepAliveSubscription` and pass it any options that fit your needs.
 * When your component renders, `useKeepAliveSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeepAliveSubscription({
 *   variables: {
 *   },
 * });
 */
export function useKeepAliveSubscription(baseOptions?: Apollo.SubscriptionHookOptions<KeepAliveSubscription, KeepAliveSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<KeepAliveSubscription, KeepAliveSubscriptionVariables>(KeepAliveDocument, options);
      }
export type KeepAliveSubscriptionHookResult = ReturnType<typeof useKeepAliveSubscription>;
export type KeepAliveSubscriptionResult = Apollo.SubscriptionResult<KeepAliveSubscription>;
export const OnCartsUpdatedDocument = gql`
    subscription OnCartsUpdated($accountId: String!) {
  onCartsUpdated(accountId: $accountId) {
    id
    orderLines {
      amount
      productNumber
      manuallyAdded
      name
      description
      images {
        url
        name
        sorting
      }
    }
    automaticOrderDate
    wasDateChanged
    comment
  }
}
    `;

/**
 * __useOnCartsUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnCartsUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCartsUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCartsUpdatedSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useOnCartsUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnCartsUpdatedSubscription, OnCartsUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnCartsUpdatedSubscription, OnCartsUpdatedSubscriptionVariables>(OnCartsUpdatedDocument, options);
      }
export type OnCartsUpdatedSubscriptionHookResult = ReturnType<typeof useOnCartsUpdatedSubscription>;
export type OnCartsUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnCartsUpdatedSubscription>;
export const OnInventoryItemAddedDocument = gql`
    subscription OnInventoryItemAdded($accountId: String!) {
  onInventoryItemAdded(accountId: $accountId) {
    id
    productNumber
    accountId
    category
    minimum
    maximum
    amount
    automaticOrderingState
    name
    description
    status
    barcode
    images {
      url
      name
      sorting
    }
    alreadyOrdered
    inCart
    isCustom
    unit
    amountPerUnit
    supplier
    price
  }
}
    `;

/**
 * __useOnInventoryItemAddedSubscription__
 *
 * To run a query within a React component, call `useOnInventoryItemAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnInventoryItemAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnInventoryItemAddedSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useOnInventoryItemAddedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnInventoryItemAddedSubscription, OnInventoryItemAddedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnInventoryItemAddedSubscription, OnInventoryItemAddedSubscriptionVariables>(OnInventoryItemAddedDocument, options);
      }
export type OnInventoryItemAddedSubscriptionHookResult = ReturnType<typeof useOnInventoryItemAddedSubscription>;
export type OnInventoryItemAddedSubscriptionResult = Apollo.SubscriptionResult<OnInventoryItemAddedSubscription>;
export const OnInventoryItemDeletedDocument = gql`
    subscription OnInventoryItemDeleted($accountId: String!) {
  onInventoryItemDeleted(accountId: $accountId) {
    id
    productNumber
    accountId
    category
    minimum
    maximum
    amount
    automaticOrderingState
    name
    description
    status
    barcode
    images {
      url
      name
      sorting
    }
    alreadyOrdered
    inCart
    isCustom
    unit
    amountPerUnit
    supplier
    price
  }
}
    `;

/**
 * __useOnInventoryItemDeletedSubscription__
 *
 * To run a query within a React component, call `useOnInventoryItemDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnInventoryItemDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnInventoryItemDeletedSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useOnInventoryItemDeletedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnInventoryItemDeletedSubscription, OnInventoryItemDeletedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnInventoryItemDeletedSubscription, OnInventoryItemDeletedSubscriptionVariables>(OnInventoryItemDeletedDocument, options);
      }
export type OnInventoryItemDeletedSubscriptionHookResult = ReturnType<typeof useOnInventoryItemDeletedSubscription>;
export type OnInventoryItemDeletedSubscriptionResult = Apollo.SubscriptionResult<OnInventoryItemDeletedSubscription>;
export const OnInventoryItemHistoryUpdatedDocument = gql`
    subscription OnInventoryItemHistoryUpdated($accountId: String!) {
  onInventoryItemHistoryUpdated(accountId: $accountId) {
    id
    productIds
    eventTime
    eventType
    eventAction
    productNumber
    accountId
    eventReverted
    revertTime
    fromOrderNumber
  }
}
    `;

/**
 * __useOnInventoryItemHistoryUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnInventoryItemHistoryUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnInventoryItemHistoryUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnInventoryItemHistoryUpdatedSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useOnInventoryItemHistoryUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnInventoryItemHistoryUpdatedSubscription, OnInventoryItemHistoryUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnInventoryItemHistoryUpdatedSubscription, OnInventoryItemHistoryUpdatedSubscriptionVariables>(OnInventoryItemHistoryUpdatedDocument, options);
      }
export type OnInventoryItemHistoryUpdatedSubscriptionHookResult = ReturnType<typeof useOnInventoryItemHistoryUpdatedSubscription>;
export type OnInventoryItemHistoryUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnInventoryItemHistoryUpdatedSubscription>;
export const OnInventoryItemUpdatedDocument = gql`
    subscription OnInventoryItemUpdated($accountId: String!) {
  onInventoryItemUpdated(accountId: $accountId) {
    id
    productNumber
    accountId
    category
    minimum
    maximum
    amount
    automaticOrderingState
    name
    description
    status
    barcode
    images {
      url
      name
      sorting
    }
    alreadyOrdered
    inCart
    isCustom
    unit
    amountPerUnit
    supplier
    price
  }
}
    `;

/**
 * __useOnInventoryItemUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnInventoryItemUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnInventoryItemUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnInventoryItemUpdatedSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useOnInventoryItemUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnInventoryItemUpdatedSubscription, OnInventoryItemUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnInventoryItemUpdatedSubscription, OnInventoryItemUpdatedSubscriptionVariables>(OnInventoryItemUpdatedDocument, options);
      }
export type OnInventoryItemUpdatedSubscriptionHookResult = ReturnType<typeof useOnInventoryItemUpdatedSubscription>;
export type OnInventoryItemUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnInventoryItemUpdatedSubscription>;
export const OnOrderAddedDocument = gql`
    subscription OnOrderAdded($accountId: String!) {
  onOrderAdded(accountId: $accountId) {
    id
    accountId
    orderLines {
      id
      status
      name
      description
      price
      taken
      amount
      productNumber
      excluded
      barcode
      images {
        url
        name
        sorting
      }
    }
    acknowledged
    packed
    picked
    completed
    orderedFrom
    orderDate
    comments
  }
}
    `;

/**
 * __useOnOrderAddedSubscription__
 *
 * To run a query within a React component, call `useOnOrderAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnOrderAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnOrderAddedSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useOnOrderAddedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnOrderAddedSubscription, OnOrderAddedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnOrderAddedSubscription, OnOrderAddedSubscriptionVariables>(OnOrderAddedDocument, options);
      }
export type OnOrderAddedSubscriptionHookResult = ReturnType<typeof useOnOrderAddedSubscription>;
export type OnOrderAddedSubscriptionResult = Apollo.SubscriptionResult<OnOrderAddedSubscription>;
export const OnOrderDeletedDocument = gql`
    subscription OnOrderDeleted($accountId: String!) {
  onOrderDeleted(accountId: $accountId) {
    id
    accountId
    orderLines {
      id
      status
      name
      description
      price
      taken
      amount
      productNumber
      excluded
      barcode
      images {
        url
        name
        sorting
      }
    }
    acknowledged
    packed
    picked
    completed
    orderedFrom
    orderDate
    comments
  }
}
    `;

/**
 * __useOnOrderDeletedSubscription__
 *
 * To run a query within a React component, call `useOnOrderDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnOrderDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnOrderDeletedSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useOnOrderDeletedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnOrderDeletedSubscription, OnOrderDeletedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnOrderDeletedSubscription, OnOrderDeletedSubscriptionVariables>(OnOrderDeletedDocument, options);
      }
export type OnOrderDeletedSubscriptionHookResult = ReturnType<typeof useOnOrderDeletedSubscription>;
export type OnOrderDeletedSubscriptionResult = Apollo.SubscriptionResult<OnOrderDeletedSubscription>;
export const OnOrderErrorDocument = gql`
    subscription OnOrderError {
  onOrderError {
    firstErrorTime
    currentlyActive
  }
}
    `;

/**
 * __useOnOrderErrorSubscription__
 *
 * To run a query within a React component, call `useOnOrderErrorSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnOrderErrorSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnOrderErrorSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnOrderErrorSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnOrderErrorSubscription, OnOrderErrorSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnOrderErrorSubscription, OnOrderErrorSubscriptionVariables>(OnOrderErrorDocument, options);
      }
export type OnOrderErrorSubscriptionHookResult = ReturnType<typeof useOnOrderErrorSubscription>;
export type OnOrderErrorSubscriptionResult = Apollo.SubscriptionResult<OnOrderErrorSubscription>;
export const OnOrderUpdatedDocument = gql`
    subscription OnOrderUpdated($accountId: String!) {
  onOrderUpdated(accountId: $accountId) {
    id
    accountId
    orderLines {
      id
      status
      name
      description
      price
      taken
      amount
      productNumber
      excluded
      barcode
      images {
        url
        name
        sorting
      }
      sorting
    }
    acknowledged
    packed
    picked
    completed
    orderedFrom
    orderDate
    comments
  }
}
    `;

/**
 * __useOnOrderUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnOrderUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnOrderUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnOrderUpdatedSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useOnOrderUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnOrderUpdatedSubscription, OnOrderUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnOrderUpdatedSubscription, OnOrderUpdatedSubscriptionVariables>(OnOrderUpdatedDocument, options);
      }
export type OnOrderUpdatedSubscriptionHookResult = ReturnType<typeof useOnOrderUpdatedSubscription>;
export type OnOrderUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnOrderUpdatedSubscription>;
export const OnScansUpdatedDocument = gql`
    subscription onScansUpdated($accountId: String) {
  onScansUpdated(accountId: $accountId) {
    id
    accountId
    items {
      id
      amount
      barcode
      eventIds
      eventTime
      productIds
    }
  }
}
    `;

/**
 * __useOnScansUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnScansUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnScansUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnScansUpdatedSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useOnScansUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnScansUpdatedSubscription, OnScansUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnScansUpdatedSubscription, OnScansUpdatedSubscriptionVariables>(OnScansUpdatedDocument, options);
      }
export type OnScansUpdatedSubscriptionHookResult = ReturnType<typeof useOnScansUpdatedSubscription>;
export type OnScansUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnScansUpdatedSubscription>;